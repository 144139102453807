// Layout.js
import React, { useEffect } from 'react';
import MKBox from "components/MKBox";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import routes from "routes";
import Footer from "components/Footers/SimpleFooter";


const ContactLayout = ({ children }) => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
   }, []);
 
    return (
      <>
        {/* Common layout components */}
        <MKBox position="fixed" top="0.5rem" width="100%" zIndex="10" >
          <DefaultNavbar routes={routes} relative />
        </MKBox>
        <MKBox
          justifyContent="center"
          display="flex"
          zIndex="5"
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 15, sm: 14, md: 15 }}
            mb={{ xs: 4, sm: 3, md: 4 }}
            mx={3}
            maxWidth={600}
          >
            <main>{children}</main>
            <Footer />
          </MKBox>
        </MKBox>
      </>
    );
  };
export default ContactLayout;