/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import CenteredAuthenticationCard from "components/Cards/AuthenticationCards/CenteredAuthenticationCard";
import { checkSignInStatus } from "assets/utils";
import { Authenticator } from "@aws-amplify/ui-react";
//import { syncCheckSignInStatus } from "assets/utils";

function SignInModal() {
  const [show, setShow] = useState(false);
  const [signInStatus, setSignInStatus] = useState(false);
  const toggleModal = () => setShow(!show);

  checkSignInStatus().then(result => {
    if (result == !signInStatus) {
      console.log("signInStatus does not match. Updating.");
      setSignInStatus(result)
    } else {
      console.log("signInStatus matches. Doing nothing.");
    }
  }
  );

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid container item xs={12} lg={10} justifyContent="center" mx="auto">
          { (signInStatus === true) ? <Authenticator>
          {({ signOut, user }) => (
            <MKTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="regular">
              Signed in as {user.username}
              <MKButton onClick={signOut}>Sign out</MKButton>
            </MKTypography>
          )}
        </Authenticator> : 
            <MKTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="regular">
              Checking out as Guest
              <MKButton variant="gradient" color="info" onClick={toggleModal}>
                SignIn
              </MKButton>
            </MKTypography>
          }
        </Grid>
        <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={show} timeout={500}>
            <MKBox
              position="relative"
              
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Sign In</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <CenteredAuthenticationCard />
            </MKBox>
          </Slide>
        </Modal>
      </Container>
    </MKBox>
  );
}

export default SignInModal;
