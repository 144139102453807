/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";

// Material Kit 2 React components
import TransparentImageCard from "components/Cards/AlbumCards/TransparentImageCard";
import { Link } from "react-router-dom";

import MKPagination from "components/MKPagination";
import Icon from "@mui/material/Icon";


import React, { useState, useEffect } from "react";

import { getAlbum } from 'assets/customgraphql/queries';

import { useParams } from 'react-router-dom';
import { dynamicSort } from "assets/utils";
import ImageModal from "layouts/sections/attention-catchers/modals/components/ImageModal";
import { generateClient } from "aws-amplify/api";
import { getUrl } from "aws-amplify/storage";






function AlbumSection() {
  const { id, pagenum } = useParams();
  const [albumlist, updateAlbumList] = useState([]);
  const [albumlistpage, updateAlbumListPage] = useState([]);
  const [albumpages, updateAlbumPages] = useState([]);
  const [albumname, updateAlbumName] = useState([]);
  const [hasVids, updateHasVids] = useState(false);

  const [albumlistfiltered, updateAlbumListFiltered] = useState([]);
  const [albumlistfilterquery, updateAlbumListFilterquery] = useState([]);


  const [numpages, updateNumPages] = useState([]);
  const [pagelist, updatePageList] = useState([]);

  const [selectedPostIndex, updateSelectedPostIndex] = useState(null);
  const handlePostClick = (postIndex => {
    console.log("handlePostClick triggered: ", postIndex);
    updateSelectedPostIndex(parseInt(postIndex))
  });
  const handleCloseImageModal = () => { updateSelectedPostIndex(null)};

  const handleNext = () => {
    updateSelectedPostIndex((prevIndex) => (prevIndex + 1));
  }

  const handlePrev = () => {
    updateSelectedPostIndex((prevIndex) => (prevIndex - 1));
  }


  const itemsPerPage = 40;
  useEffect(() => {
    fetchAlbum(id);
  }, [id]);

  useEffect(() => {
    renderAlbumPage(pagenum);
  }, [pagenum, albumlistfiltered, numpages,  hasVids])

  //  Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [id, pagenum]);


  async function fetchAlbum(id) {
    const APIClient = generateClient()
    let albumData = await APIClient.graphql({ query: getAlbum, variables: { id } });
    let albumsArray = albumData.data.getAlbum.photos.items;
    // console.log(albumData);
    //console.log(albumsArray);
    if (albumData.data.getAlbum.videos.items.length > 0) {
      updateHasVids(true);
    } else {
      updateHasVids(false);
    }
    updateAlbumName(albumData.data.getAlbum.name);
    albumsArray.sort(dynamicSort("createdAt"));

    updateAlbumList(albumsArray);
    updateAlbumListFiltered(albumsArray);
  }

  const handleChange = (e) => {
    updateAlbumListFilterquery(e.target.value)
    filterAlbum(e.target.value)
  }

  function filterAlbum(filterstring) {
    //grab the full list (albumlist)
    //check the filter string is not blank
    if (filterstring === "") {
      updateAlbumListFiltered(albumlist);
      return;
    }
    //iterate through it to find matches
    const results = albumlist.filter(image => {
      if (!image.plates) return
      return String(image.plates).includes(filterstring)
    })
    //add the matches to a new array
    //export the arry to the filteredlist
    updateAlbumListFiltered(results);

  }



  async function renderAlbumPage(pagenum) {
    updateNumPages(Math.ceil(albumlistfiltered.length / itemsPerPage));
    updateAlbumPages()
    // console.log("Start renderAlbumPage");
    // console.log("Number pages: " + numpages);
    // console.log("This page: " + pagenum)
    // Now need to loop through the albumlist object, and select only the items for this page (albumlistpage)
    let pagearray = albumlistfiltered.slice((pagenum - 1) * itemsPerPage, pagenum * itemsPerPage);
    // console.log(pagearray);

    let promisearray = [];
    await pagearray.map((image) => {
      let promise = getUrl({path: "public/processed/tn-" + image.image}).then(result => { image.imageurl = result.url.href });
      promisearray.push(promise);
      let dlpromise = getUrl({path: "public/processed/wm-" + image.image}).then(result => { image.dlurl = result.url.href });
      promisearray.push(dlpromise);
    }
    )
    Promise.all(promisearray).then((values) => {
      // console.log("All promises returned, running updateAlbumListPage");
      updateAlbumListPage(pagearray);
    }
    )
    let pageswidth = 5;
    let pagesperside = (Math.floor(pageswidth / 2));
    let pagesmin = pagenum - pagesperside;
    let pagesmax = +pagenum + pagesperside;

    var pagelistarr = []
    for (var i = 0; i < numpages; i++) {
      // console.log("ForLoop, i = " + i);
      var obj = { "pagenum": (i + 1), "active": (i + 1 === pagenum) };
      //console.log(obj);
      // console.log(pagesmin);
      // console.log(pagenum);
      // console.log(pagesmax);
      if ((i + 1 >= pagesmin) & (i + 1 <= pagesmax)) {
        pagelistarr.push(obj)
      };
    }
    // console.log("PageListArr: ");
    // console.log(pagelistarr);
    updatePageList(pagelistarr);




  }




  return (
    <MKBox component="section" py={0} >
      <ImageModal show={selectedPostIndex !== null} albumlistpage={albumlistpage} albumname={albumname} selectedpostindex={parseInt(selectedPostIndex)} onClose={handleCloseImageModal} onPrev={handlePrev} onNext={handleNext} />
      <Container>
        <Grid container item >
          <MKTypography variant="h3" mb={1} mt={1}>
            {albumname}
          </MKTypography>
        </Grid>
        {(parseInt(pagenum, 10) === 1) && <Grid container item mb={1}><MKInput variant="outlined" label="Plate search" onChange={handleChange} /></Grid>}
        {(parseInt(pagenum, 10) === 1 && hasVids ) && <Grid container item mb={1}><MKButton variant="gradient" color="info" href={"/albumvids/"+id+"/page/1"} >View videos</MKButton></Grid>}
        <Grid container spacing={3}>

          {albumlistpage.map((item, itemIndex) => (
            <Grid item xs={6} lg={3} key={item.id} onClick={() => handlePostClick(itemIndex)}>
              <TransparentImageCard
                image={item.imageurl}
                dlurl={item.dlurl}
                title=""
                description=""
              />
              {/* <ImageModal postinfo={item}> </ImageModal> */}
            </Grid>


          ))}

        </Grid>
      </Container>
      <Container >
        <MKPagination placement="center" key="paginationSection">
          {(parseInt(pagenum, 10) > 1) && <Link to={"page/" + (parseInt(pagenum, 10) - 1)}><MKPagination key="pageless" item><Icon>keyboard_arrow_left</Icon></MKPagination></Link>}
          {pagelist.map((item) => (
            <Link to={"page/" + item.pagenum} key={"link" + item.pagenum}><MKPagination item active={item.active} key={"page" + item.pagenum}>{item.pagenum}</MKPagination></Link>
          ))}

          {/* list of pages here */}


          {(parseInt(pagenum, 10) < parseInt(numpages, 10)) && <Link to={"page/" + (parseInt(pagenum, 10) + 1)}><MKPagination key="pagemore" item><Icon>keyboard_arrow_right</Icon></MKPagination></Link>}
        </MKPagination>
      </Container>
    </MKBox>
  );
}

export default AlbumSection;
