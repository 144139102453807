/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import CircularProgress from '@mui/material/CircularProgress';


import { commercialUseReminder, personalUseReminder } from "./spiels";
import React from "react";

import { fetchLineItemDownloadLink } from "graphql/queries";
import { whichAuthMode } from "assets/utils";
import { generateClient } from "aws-amplify/api";

function DownloadLineItemModal({ orderId, lineItem, enabled }) {
  //const { postid, prices, addItem } = props;
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const [isLoading, updateIsLoading] = useState(false);


  function getDisclaimer(disclaimer) {

    return disclaimer.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  }

  const handleDownload = async (orderId, productId) => {
    const APIClient = generateClient();
    updateIsLoading(true);

    try {
      let authMode = await whichAuthMode();

      const response = await APIClient.graphql({
        query: fetchLineItemDownloadLink,
        authMode: authMode,
        variables: {
          input: {
            orderId: orderId,
            productId: productId
          }
        }
      })

      console.log(response);
      if (response.data.fetchLineItemDownloadLink) {
        console.log("Response contains a link: ", response.data.fetchLineItemDownloadLink);

        fetch(response.data.fetchLineItemDownloadLink)
          .then(response => {
            console.log(response);
            if (response.status === 200) {
              response.blob().then(blob => {
                const dlObject = window.URL.createObjectURL(new Blob([blob]));
                const dlA = document.createElement('a');
                dlA.href = dlObject;
                dlA.download = productId + ".jpg";
                document.body.appendChild(dlA);
                dlA.click();
                document.body.removeChild(dlA);
              }
              )
            } else {
              console.error("Error fetching the object");
            }
          })
          .catch(error => {
            console.error('Download error: ', error.message);
            updateIsLoading(false);

          });

        var downloadItem = document.createElement("a");
        downloadItem.setAttribute('download', '');
        downloadItem.href = response.data.fetchLineItemDownloadLink;
        //downloadItem.click();
        downloadItem.remove();
        updateIsLoading(false);


      }
    } catch (error) {
      console.log("Error: ", error)
      updateIsLoading(false);

    }

  }


  return (
    <>
      <MKBox>

        <MKBox
          component="img"
          src={lineItem.postInfo.imageurl}
          alt={"title"}
          borderRadius="lg"
          shadow="md"
          maxWidth="15rem"
          maxHeight="15rem"
          position="relative"

          onClick={enabled ? toggleModal : null}
          zIndex={1} />
      </MKBox>
      <MKBox>
        <MKButton
          m="auto"
          zindex="9"
          variant="gradient"
          color="info"
          onClick={enabled ? toggleModal : null}
        >Download this image</MKButton>
      </MKBox>

      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={show} timeout={500}>
          <MKBox
            position="relative"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
            maxWidth="550px"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Reminder</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <MKTypography variant="body2" color="secondary" fontWeight="regular">
                {(lineItem.priceData.license === "commercial") ?
                  getDisclaimer(commercialUseReminder) :
                  getDisclaimer(personalUseReminder)}
              </MKTypography>
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>

              <MKBox display="flex" alignItems="center" justifyContent="center">
                <MKButton
                  m="auto"
                  zindex="9"
                  variant="gradient"
                  color="info"
                  onClick={() => handleDownload(orderId, lineItem.productId)}
                >Download for {(lineItem.priceData.license === "commercial") ? "commercial" : "personal"} use</MKButton>
              </MKBox>
              {isLoading ?
                <MKBox position="absolute" zIndex="3" top="50%" left="50%" >
                  <CircularProgress />
                </MKBox> : null
              }


            </MKBox>

          </MKBox>
        </Slide>
      </Modal>
    </>
  );
}

export default DownloadLineItemModal;
