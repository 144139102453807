/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import InstagramIcon from "@mui/icons-material/Instagram";

// Pages
//import ContactUs from "layouts/pages/landing-pages/contact-us";
import CommercialUse from "pages/LandingPages/CommercialUse";
import Contact from "pages/LandingPages/Contact";
import PersonalUse from "pages/LandingPages/PersonalUse";
import Albums from "layouts/pages/landing-pages/albums";
import AlbumGroup from "pages/LandingPages/AlbumGroup";
import Album from "layouts/pages/landing-pages/album";
//import SignIn from "layouts/pages/authentication/sign-in";
import Admin from "pages/LandingPages/Admin";

// Sections
// import PageHeaders from "layouts/sections/page-sections/page-headers";
// import Features from "layouts/sections/page-sections/featuers";
// import Navbars from "layouts/sections/navigation/navbars";
// import NavTabs from "layouts/sections/navigation/nav-tabs";
// import Pagination from "layouts/sections/navigation/pagination";
// import Inputs from "layouts/sections/input-areas/inputs";
// import Forms from "layouts/sections/input-areas/forms";
// import Alerts from "layouts/sections/attention-catchers/alerts";
// import Modals from "layouts/sections/attention-catchers/modals";
// import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
// import Avatars from "layouts/sections/elements/avatars";
// import Badges from "layouts/sections/elements/badges";
// import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
// import Buttons from "layouts/sections/elements/buttons";
// import Dropdowns from "layouts/sections/elements/dropdowns";
// import ProgressBars from "layouts/sections/elements/progress-bars";
// import Toggles from "layouts/sections/elements/toggles";
// import Typography from "layouts/sections/elements/typography";

import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import React from "react";
import { listLatestAlbums, listLatestAlbumGroups } from 'assets/customgraphql/queries';
import { dynamicSort } from "assets/utils";
import ShoppingCart from "pages/LandingPages/ShoppingCart";
import { useShoppingCart } from "use-shopping-cart";
// import { checkSignInStatus } from "assets/utils";
import Orders from "pages/LandingPages/Orders";
import Terms from "pages/LandingPages/Policies";
import { generateClient } from "aws-amplify/api";


Amplify.configure(config);
console.log(Amplify.Auth);
//const user = await Auth.currentAuthenticatedUser();
//console.log(user );

// Create a function to generate a list of current events
// const seriesRoutes = [
  //   {
    //     name: "2023 NSW Gravity Enduro",
    //     route: "/pages/landing-pages/about-us",
    //     component: <AboutUs />,
    //   },
    //   {
      //     name: "2023 Greenvalleys Cartel Series",
      //     route: "/pages/landing-pages/contact-us",
      //     component: <ContactUs />,
      //   },
      //   {
        //     name: "2023 SCUM Flow X Series",
        //     route: "/pages/landing-pages/author",
        //     component: <Author />,
        //   },
        // ];
        
        function getAlbumsToRoutes (graphqlQuery) {
          const APIClient = generateClient();
          let albumroutes = []
          APIClient.graphql({ query: graphqlQuery }).then((result) => {
            result.data.listAlbums.items.sort(dynamicSort("-createdAt"));
            result.data.listAlbums.items.slice(0,4).map((album) => {
              albumroutes.push({"name": album.name, "route": "/album/" + album.id, "createdAt": album.createdAt, component: <Album />});
            })
            console.log(albumroutes);

          });
          
          return albumroutes;
        }
        
        function getAlbumGroupsToRoutes (graphqlQuery) {
          const APIClient = generateClient();
          let albumgrouproutes = []
          APIClient.graphql({ query: graphqlQuery }).then((result) => {
            result.data.listAlbumGroups.items.sort(dynamicSort("-createdAt"));
            result.data.listAlbumGroups.items.slice(0,4).map((albumGroup) => {
              albumgrouproutes.push({"name": albumGroup.name, "route": "/albumGroup/" + albumGroup.id, "createdAt": albumGroup.createdAt, component: <AlbumGroup />});
            })
            console.log(albumgrouproutes);

          });
          
          return albumgrouproutes;
        }

// function GetCartLinkString (CartProvider){
//   const { cartCount, totalPrice } = useShoppingCart()
//   let getCartLinkString = cartCount + " items in cart - " + totalPrice
//   console.log(getCartLinkString)
//   return getCartLinkString
// }

// function getAccountSection () {
//   const signInStatus = syncCheckSignInStatus();
//   console.log("signInStatus is: ", signInStatus);
//   let collapse = [];
//   if (true) {
//     collapse.push(
//       {
//         name: "Orders",
//         route: "/orders",
//         component: <Orders />
//       }
//     )
//   } else {
//     collapse.push(
//       {
//         name: "SignIn"
//       }
//     )
//   }
//   console.log("Built the accountSection")
//   return collapse

// }

const latestAlbumsRoutes = getAlbumsToRoutes(listLatestAlbums);
const latestAlbumGroupRoutes = getAlbumGroupsToRoutes(listLatestAlbumGroups);
//const accountSection = getAccountSection();
// var adminRoutes = {}
// adminRoutes = {
//     name: "Admin",
//     route: "/admin",
//     component: <Admin />,
//     collapse:[
//       {
//         name: "Albums",
//         route: "/admin",
//         component: <Admin />
//       },
//       {
//         name: "Posts",
//         route: "/admin",
//         component: <Admin />
//       },
//       {
//         name: "Series",
//         route: "/admin",
//         component: <Admin />
//       },
//       {
//         name: "Upload",
//         route: "/admin",
//         component: <Admin />
//       },
//     ]
//   }


const routes = [
  {
    name: "events",
    icon: <Icon>dashboard</Icon>,
        collapse: latestAlbumGroupRoutes,
  },

  {
    name: "photo albums",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "all albums",
        route: "/albums",
        component: <Albums />,
        collapse:[
          {
            name: "view all albums",
            route: "/albums",
            component: <Albums />
          }
        ]
      },
      {
        name: "latest event albums",
        collapse: latestAlbumsRoutes,
      },
      
      
    ],
  },
  //adminRoutes,
  {
    name: "about",
    icon: <Icon>dashboard</Icon>,
    collapse: [
      {
        name: "Contact",
        route: "/contact",
        component: <Contact />
      },
      {
        name: "Terms of Service",
        route: "/tos",
        component: <Terms />
      },
      {
        name: "Personal use",
        route: "/personal-use",
        component: <PersonalUse />
      },
      {
        name: "Commercial use",
        route: "/commercial-use",
        component: <CommercialUse />
      },
  //     {
  //       name: "sign in",
  //       route: "/pages/authentication/sign-in",
  //       component: <SignIn />,
  //     },
      ],
    },
    {
      name: "Account",
      icon: <Icon>dashboard</Icon>,
      //collapse: accountSection,
      collapse: [{
        name: "Orders",
        route: "/orders",
        component: <Orders />
      }],
  },
  {
    name: "instagram",
    icon: <InstagramIcon />,
    href: "https://www.instagram.com/shanmtb/",
  },
  {
    //name: GetCartLinkString(CartProvider),
    name: "Shopping Cart",
    icon: <Icon>shopping</Icon>,
    route: "/cart",
    component: <ShoppingCart/>
  },
];

export default routes;
