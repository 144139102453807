/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKProgress from "components/MKProgress";



import React, { useState, useEffect, useMemo, useRef,} from "react";
import { v4 as uuid } from 'uuid';


import { createAlbum } from 'graphql/mutations';
import { generateClient } from "aws-amplify/api";


/* Initial state to hold form input, saving state */
const initialState = {
  name: '',
  saving: false
};






export default function CreateAlbum({
}) {

  /* 1. Create local state with useState hook */
  const [formState, updateFormState] = useState(initialState)

  /* 2. onChangeText handler updates the form state when a user types into a form field */
  function onChangeText(e) {
    console.log("onChangeText");
    console.log(e);
    e.persist();
    updateFormState(currentState => ({ ...currentState, [e.target.name]: e.target.value }));
  }

//  /* 3. onChangeFile handler will be fired when a user uploads a file  */
//  function onChangeFile(e) {
//    e.persist();
//    if (! e.target.files[0]) return;
//    const fileExtPosition = e.target.files[0].name.search(/.png|.jpg|.gif/i);
//    const firstHalf = e.target.files[0].name.slice(0, fileExtPosition);
//    const secondHalf = e.target.files[0].name.slice(fileExtPosition);
//    const fileName = firstHalf + "_" + uuid() + secondHalf;
//    console.log(fileName);
//    const image = { fileInfo: e.target.files[0], name: fileName};
//    updateFormState(currentState => ({ ...currentState, file: URL.createObjectURL(e.target.files[0]), image }))
//  }

  /* 4. Save the Album  */
  async function save() {
    const APIClient = generateClient()
    console.log("save");
    
    try {
      const { name } = formState;
      console.log(name);
      if (!name ) return;
      updateFormState((currentState) => ({ ...currentState, saving: true }));
      const albumId = uuid();
      const albumInfo = {
        name,
        id: albumId,
      };
  
      await APIClient.graphql({
        query: createAlbum,
        variables: { input: albumInfo },
        authMode: "userPool", //FixMe
      }); // updated
      updateFormState((currentState) => ({ ...currentState, saving: false }));
    } catch (err) {
      console.log("error: ", err);
    }
  }

  return (

    <><MKBox component="section" py={2}>
          <MKTypography variant="h3" mb={6}>
            Create Album
          </MKTypography>
          <MKBox p={12}>
            <MKBox width="100%" component="form" method="post" autoComplete="off">
                  <MKInput
                    key="AlbumName"
                    label="Album Name"
                    name="name"
                    onChange={onChangeText}
                  >
                  </MKInput>
                  <MKButton variant="gradient" color="info" onClick={save}>
                    Create Album
                  </MKButton>

            </MKBox>
          </MKBox>
    </MKBox></>
  )
}

