/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function CenteredAuthenticationCard({  }) {
  return (
    <Card>

      <MKBox p={3} mt={-1} textAlign="center">
        <Authenticator>
          {({ signOut, user }) => (
            <MKTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="regular">
              Signed in as {user.username}
              <MKButton onClick={signOut}>Sign out</MKButton>
            </MKTypography>
          )}
        </Authenticator>
      </MKBox>
    </Card>
  );
}

// Typechecking props for the CenteredBlogCard
CenteredAuthenticationCard.propTypes = {
};

export default CenteredAuthenticationCard;
