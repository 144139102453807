/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Footer() {
  return (
    <MKBox component="footer" py={6}>
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={12}
            //textAlign={{ xs: "center", lg: "left" }}
            textAlign={"center"}
            mr="auto"
            mb={{ xs: 3, lg: 0 }}
          >
            <MKTypography variant="button" opacity={0.8}>
      <Link href="http://creativecommons.org/licenses/by-nc-sa/4.0/" > 
      <img src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png" alt="Creative Commons License" />
      </Link>
        <br />This work is licensed under a <a href="http://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License</a>.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={12} ml="auto" 
          //textAlign={{ xs: "center", lg: "right" }}
          textAlign={"center"}
          >
            <MKTypography
              component={Link}
              href="https://instagram.com/shanmtb"
              target="_blank"
              rel="noreferrer"
              variant="body2"
              color="dark"
              opacity={0.5}
            >
              <i className="fab fa-instagram" />
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Footer;
