/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://v6tpthaltfhizahbelgw3ffbze.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6amwlxxnxvcgtifqlwyjisodve",
    "aws_cognito_identity_pool_id": "ap-southeast-2:68da7862-ce71-4317-bac3-41cedd5b6397",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_CoUEe3asF",
    "aws_user_pools_web_client_id": "665r6jmrnr2nb23pbhl747ugkm",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "shangram8e63b805941b447d805f064b5237d03c121754-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
