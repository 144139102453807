/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { AddShoppingCart } from "@mui/icons-material";
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'

function BuyImageModal({ postid, prices, image }) {
  //const { postid, prices, addItem } = props;
  const [show, setShow] = useState(false);
  const [added, setAdded] = useState(false);
  const toggleModal = () => {
    //setAdded(false);
    setShow(!show);
  }
  const { totalPrice, clearCart, addItem, cartDetails } = useShoppingCart()
  console.log(image);

  useEffect(() => {
    console.log(show);
    if (!show) {
      setTimeout(() => setAdded(false), 600);
    }

  }, [show])

  return (
    <>
      <MKButton variant="gradient" color="info" onClick={toggleModal}>
        <AddShoppingCart icononly="false" />
        Buy this image
      </MKButton>
      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={show} timeout={500}>
          <MKBox
            position="relative"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            {
              (added) ? <>
                <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                  <MKTypography variant="h5">Item added to cart!</MKTypography>
                  <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                </MKBox>
                <Divider sx={{ my: 0 }} />

                <MKButton
                  m="auto"
                  zindex="9"
                  variant="gradient"
                  color="info"
                  href={"/cart"}

                >View Cart </MKButton>


              </>

                : <>
                  <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                    <MKTypography variant="h5">Choose your image</MKTypography>
                    <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                  </MKBox>
                  <Divider sx={{ my: 0 }} />
                  {
                    prices.map((product) => (
                      <MKBox p={2}>
                        <MKBox>
                          <MKBox display="flex" justifyContent="space-between">
                            <MKBox> Permitted use: </MKBox> <MKBox> {product.license} use <p /> </MKBox>
                          </MKBox>
                          <MKBox display="flex" justifyContent="space-between">
                            <MKBox> Image size: </MKBox> <MKBox>{(product.max_size) ? product.max_size + " pixels along long edge" : "Full original image"} </MKBox>

                          </MKBox>
                          <MKBox display="flex" justifyContent="space-between">
                            <MKBox>

                              {formatCurrencyString({
                                value: product.price,
                                currency: product.currency,
                              })}

                            </MKBox>
                            <MKBox>

                              <MKButton
                                m="auto"
                                zindex="9"
                                variant="gradient"
                                color="info"

                                onClick={() => {
                                  product.image = image;
                                  addItem(product);
                                  setAdded(true);
                                  //toggleModal();

                                }}
                              >Add to Cart </MKButton>
                            </MKBox>
                          </MKBox>
                        </MKBox>
                      </MKBox>
                    )
                    )}
                </>
            }


          </MKBox>
        </Slide>
      </Modal>
    </>
  );
}

export default BuyImageModal;
