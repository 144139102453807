import { createWebPushAlbumSubscription, notifyAlbumSubscribers } from "graphql/mutations";
import { getPost } from "graphql/queries";
//import { getAlbum } from "graphql/queries";
//import { generateClient, GRAPHQL_AUTH_MODE } from "aws-amplify/api";
import { generateClient } from "aws-amplify/api";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { getUrl } from 'aws-amplify/storage';

export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers, 
    * and you may want to customize it to your needs
    */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}


export async function subscribeAlbum(album = "e3abbc49-75d7-4e29-86dc-30c458968e8d") {
  const APIClient = generateClient();

  const vapidPublicKey = (process.env.REACT_APP_VAPID_PUBLIC_KEY ? process.env.REACT_APP_VAPID_PUBLIC_KEY : "BGOdfHq9ldsOrNSfqfyKLjGZGzI9673IFNpoEaCS-yQEUa22xD2sujLqm2KcOi76eV4_BXWjoaeRCnU063wZGD0"); //The dev public key
  console.log(vapidPublicKey);
  console.log(album);

  const swReg = await navigator.serviceWorker.register("/sw.js");
  const subscription = await swReg.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlB64ToUint8Array(vapidPublicKey),
  });
  console.log(subscription);

  await APIClient.graphql({
    query: createWebPushAlbumSubscription,
    variables: {
      input: {
        albumID: album,
        subscription: JSON.stringify(subscription)
      }
    }
  })

}
function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}


export async function notifySubscribers(album = "e3abbc49-75d7-4e29-86dc-30c458968e8d") {

  console.log(album);
  const APIClient = generateClient();

  await APIClient.graphql({
    query: notifyAlbumSubscribers,
    variables: {
      input: { id: album }
    },
    //authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    authMode: "userPool" //FixMe
  })
}



// Check if a user is signed in
export const checkSignInStatus = async () => {
  let result = false;
  try {
    // Use Auth.currentAuthenticatedUser() to get the current authenticated user
    const user = await getCurrentUser();

    // If the user object is returned without an error, the user is signed in
    console.log('User is signed in');
    result = true;
    return result;
  } catch (error) {
    // If an error occurs, the user is not signed in
    console.log('User is not signed in');
    return false;
  }
};

export const syncCheckSignInStatus = () => {
  // this function should return a boolean for whether the current session is for a registered user (true) or guest (false)
  //console.log("syncCheckSignInStatus called")

  // need to force this function to wait for the async function to finish, without making this function async.
  
  if (checkSignInStatus().then(result => {return result}) === true) {
    return true;
  } else {
    return false;
  }
  let result = false;
    let promise = fetchAuthSession().then(AuthSession => {
      if (AuthSession.userSub === undefined) {
        console.log("AuthSession.userSub was undefined")
        result = false
      } else {
        console.log("AuthSession.userSub was defined as " + String(AuthSession.userSub) )
        result = true
      } 
      return result;
    }) 
  promise.then(result => {
    return result;
  })
}

export const whichAuthMode = async () => {
  try {
    // Use Auth.currentAuthenticatedUser() to get the current authenticated user
    const user = await getCurrentUser();

    // If the user object is returned without an error, the user is signed in
    console.log('User is signed in:', user);
    return "userPool";
  } catch (error) {
    // If an error occurs, the user is not signed in
    console.log('User is not signed in:', error);
    return "apiKey";
  }
};

export const signInAsGuest = async () => {
  try {
    // Check if the user is already authenticated
    const user = await getCurrentUser();
    console.log('User is already authenticated:', user);
  } catch (notAuthenticatedError) {
    // Sign in as a guest if not authenticated
    try {
      //await Auth.signInGuest();
      console.log('Signed in as guest');
    } catch (guestSignInError) {
      console.error('Error signing in as guest:', guestSignInError);
    }
  }
};



export async function fetchPost(id, prefix = "wm") {
  const APIClient = generateClient();

  let postData = null;
  let postInfo = null;
  try {
    postData = await APIClient.graphql({ query: getPost, variables: { id } });
    postInfo = postData.data.getPost;
  } catch ({ errors, data }) {
    if (data) {
      //console.warn("GraphQL Query returned data with errors", errors);
      postInfo = data.getPost;
    } else {
      console.error("Unhandled error", errors);
    }
  }

  let promisearray = [];
  let promise = getUrl({
    path: "public/processed/" + prefix + "-" + postInfo.image
  }).then(result => {
    postInfo.imageurl = result.url.href
  });
  promisearray.push(promise);



  await Promise.all(promisearray).then((values) => {
    return postInfo;
  }
  )
  return postInfo;
}

export async function processLineItem(item) {

  if (item.productId.split('_')[0] == "post") {
    console.log("Product is a post. Do post logic");
    let id = item.productId.split('_')[1];
    let prefix = "tn";

    await fetchPost(id, prefix).then((postInfo) => {
      item.postInfo = postInfo;
      item.postId = id;
    })

  }
  return item;

}
