/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Link } from '@mui/material';
import { Instagram } from '@mui/icons-material';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ContactLayout from "components/Layout/Contact";


function Terms() {
  return (
    <>
      <ContactLayout>

        <MKBox
          variant="gradient"
          bgColor="info"
          coloredShadow="info"
          borderRadius="lg"
          p={2}
          mx={2}
          mt={-3}
        >
          <MKTypography variant="h3" color="white">
            Terms of service
          </MKTypography>
        </MKBox>

        <MKBox p={3} className="info info-horizontal">
          <MKBox className="description">
            <MKTypography className="description">
              The terms of service and policies outlined below govern your use of our website, products, and services.
              By accessing or using our website, you agree to be bound by these Terms.
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox p={3} className="info info-horizontal">
          <MKTypography variant="h5" className="info-title">
            Contact Information
          </MKTypography>
          <MKTypography className="description">
            The best contact methods to get in touch are via email or Instagram DM. If you have placed an online order, you can reply to the order confirmation email.
          </MKTypography>
          <MKTypography className="description">
            Email: contact@shanmtb.com
          </MKTypography>
          <MKTypography className="description">
            <Link href="https://www.instagram.com/shanmtb/" className="btn btn-primary btn-round">
              Instagram: @shanmtb
            </Link>
          </MKTypography>
        </MKBox>
        <MKBox p={3} className="info info-horizontal">
          <MKTypography variant="h5" className="info-title">
            Refund and dispute policy
          </MKTypography>

          <MKTypography variant="h6" className="info-title">
            Image Licenses
          </MKTypography>
          <MKTypography className="description">
            Refunds are generally not available once your purchased image has been downloaded or otherwise delivered.
          </MKTypography>
          <MKTypography variant="h6" className="info-title">
            Physical Prints
          </MKTypography>
          <MKTypography className="description">
            As prints are made to order, with your chosen image, refunds aren't available for change of mind.
            If you're not satisfied with the product, please reach out so we can work to a remedy.
          </MKTypography>
        </MKBox>
        <MKBox p={3} className="info info-horizontal">
          <MKTypography variant="h5" className="info-title">
            Transaction currency
          </MKTypography>
          <MKTypography className="description">
            Prices listed on shanmtb.com are in AUD (Australian Dollars).
            Transactions related to shanmtb.com are processed in AUD.
            Currency conversion fees may apply if you elect to pay in a currency other than AUD.
            Any such fees are determined by your payment provider, and are paid by you.
          </MKTypography>
        </MKBox>
        <MKBox p={3} className="info info-horizontal">
          <MKTypography variant="h5" className="info-title">
            Security of payment card details
          </MKTypography>
          <MKTypography className="description">
            We utilize Stripe as our payment processor.
            By making a purchase from our website, you agree to Stripe's terms of service and privacy policy.
            Payment for your purchases from shanmtb.com is handled by Stripe. We do not handle your payment card information.
          </MKTypography>
        </MKBox>
        <MKBox p={3} className="info info-horizontal">
          <MKTypography variant="h5" className="info-title">
            Privacy and data security
          </MKTypography>
          <MKTypography className="description">

            Information collected by shanmtb.com is encrypted during storage (at-rest) and in transit.
          </MKTypography>
          <MKTypography variant="h6" className="info-title">
            Personal Information
          </MKTypography>
          <MKTypography className="description">
            Your personal information is used to facilitate the processing of orders from this website. We don't sell your info.
          </MKTypography>
          {/* <MKTypography variant="h6" className="info-title">
              Cookies
            </MKTypography>
            <MKTypography className="description">
              This website relies on cookies to function.
            </MKTypography> */}



        </MKBox>
        <MKBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="icon icon-primary">
          <Instagram />
        </MKBox>
    </ContactLayout >
    </>
  );
}

export default Terms;
