/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrderWithCsId = /* GraphQL */ `
  query GetOrderWithCsId($id: ID!, $csId: String!) {
    getOrderWithCsId(id: $id, csId: $csId) {
      id
      lineItems {
        name
        product
        productId
        priceData {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        __typename
      }
      csId
      enabled
      owner
      ttl
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const checkSfnStatus = /* GraphQL */ `
  query CheckSfnStatus($input: checkSfnStatusInput!) {
    checkSfnStatus(input: $input) {
      startDate
      stopDate
      status
      __typename
    }
  }
`;
export const getAlbumGroup = /* GraphQL */ `
  query GetAlbumGroup($id: ID!) {
    getAlbumGroup(id: $id) {
      id
      name
      owner
      albums {
        items {
          id
          name
          owner
          photos {
            nextToken
            __typename
          }
          videos {
            nextToken
            __typename
          }
          createdAt
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          updatedAt
          albumGroupAlbumsId
          __typename
        }
        nextToken
        __typename
      }
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlbumGroups = /* GraphQL */ `
  query ListAlbumGroups(
    $filter: ModelAlbumGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlbumGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        albums {
          items {
            id
            name
            owner
            createdAt
            updatedAt
            albumGroupAlbumsId
            __typename
          }
          nextToken
          __typename
        }
        prices {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlbum = /* GraphQL */ `
  query GetAlbum($id: ID!) {
    getAlbum(id: $id) {
      id
      name
      owner
      photos {
        items {
          id
          image
          pubimage
          owner
          enabled
          plates
          watermark
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          createdAt
          updatedAt
          albumPhotosId
          __typename
        }
        nextToken
        __typename
      }
      videos {
        items {
          id
          filename
          owner
          enabled
          plates
          watermark
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          createdAt
          updatedAt
          albumVideosId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      updatedAt
      albumGroupAlbumsId
      __typename
    }
  }
`;
export const listAlbums = /* GraphQL */ `
  query ListAlbums(
    $filter: ModelAlbumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlbums(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        photos {
          items {
            id
            image
            pubimage
            owner
            enabled
            plates
            watermark
            createdAt
            updatedAt
            albumPhotosId
            __typename
          }
          nextToken
          __typename
        }
        videos {
          items {
            id
            filename
            owner
            enabled
            plates
            watermark
            createdAt
            updatedAt
            albumVideosId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        prices {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        updatedAt
        albumGroupAlbumsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const albumsByCreatedAt = /* GraphQL */ `
  query AlbumsByCreatedAt(
    $createdAt: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelAlbumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    albumsByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        owner
        photos {
          items {
            id
            image
            pubimage
            owner
            enabled
            plates
            watermark
            createdAt
            updatedAt
            albumPhotosId
            __typename
          }
          nextToken
          __typename
        }
        videos {
          items {
            id
            filename
            owner
            enabled
            plates
            watermark
            createdAt
            updatedAt
            albumVideosId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        prices {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        updatedAt
        albumGroupAlbumsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      lineItems {
        name
        product
        productId
        priceData {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        __typename
      }
      csId
      enabled
      owner
      ttl
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lineItems {
          name
          product
          productId
          priceData {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          __typename
        }
        csId
        enabled
        owner
        ttl
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      image
      pubimage
      owner
      enabled
      plates
      watermark
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      albumPhotosId
      __typename
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        pubimage
        owner
        enabled
        plates
        watermark
        prices {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        createdAt
        updatedAt
        albumPhotosId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      filename
      owner
      enabled
      plates
      watermark
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      albumVideosId
      __typename
    }
  }
`;
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        filename
        owner
        enabled
        plates
        watermark
        prices {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        createdAt
        updatedAt
        albumVideosId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWebPushAlbumSubscription = /* GraphQL */ `
  query GetWebPushAlbumSubscription($id: ID!) {
    getWebPushAlbumSubscription(id: $id) {
      id
      albumID
      album {
        id
        name
        owner
        photos {
          items {
            id
            image
            pubimage
            owner
            enabled
            plates
            watermark
            createdAt
            updatedAt
            albumPhotosId
            __typename
          }
          nextToken
          __typename
        }
        videos {
          items {
            id
            filename
            owner
            enabled
            plates
            watermark
            createdAt
            updatedAt
            albumVideosId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        prices {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        updatedAt
        albumGroupAlbumsId
        __typename
      }
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWebPushAlbumSubscriptions = /* GraphQL */ `
  query ListWebPushAlbumSubscriptions(
    $filter: ModelWebPushAlbumSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebPushAlbumSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        albumID
        album {
          id
          name
          owner
          photos {
            nextToken
            __typename
          }
          videos {
            nextToken
            __typename
          }
          createdAt
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          updatedAt
          albumGroupAlbumsId
          __typename
        }
        subscription
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      prefix
      price {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        prefix
        price {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fetchCheckoutURL = /* GraphQL */ `
  query FetchCheckoutURL($input: AWSJSON) {
    fetchCheckoutURL(input: $input)
  }
`;
export const fetchLineItemDownloadLink = /* GraphQL */ `
  query FetchLineItemDownloadLink($input: LineItemDownloadLinkInput!) {
    fetchLineItemDownloadLink(input: $input)
  }
`;
