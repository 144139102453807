/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components

import React, { useState, useEffect } from "react";

import { listOrders } from "graphql/queries";
import { processLineItem } from "assets/utils";
import { OrderComponent } from "../sections/OrderComponent";
import { generateClient } from 'aws-amplify/api';


function OrdersSection() {

  const [orderlist, updateOrderList] = useState([]);
  const [triggerState, updateTriggerState] = useState([false])
  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {

  },
  [triggerState])


  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  async function fetchOrders() {
    const APIClient = generateClient();
    let orderData = await APIClient.graphql({ query: listOrders, authMode: "userPool" }); //Fixme
    let ordersArray = orderData.data.listOrders.items;
    let promisearray = [];
    await ordersArray.map((order) => (
      order.lineItems.map((item) => {
        promisearray.push(processLineItem(item));

      })
    ))

    Promise.all(promisearray).then((values) => {
      updateOrderList(ordersArray);
      console.log(ordersArray);
    })

    //updateAlbumList(albumsArray);
  }



  return (
    <MKBox component="section" py={0} px={"2rem"}>
      <Container >
        <Grid container item>
          <MKTypography variant="h3" mb={3} key="sectionHeader">
            All Orders
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>


            {orderlist.map((item) => (
              <>

              {console.log(item)}
              <OrderComponent order={item} />
              </>
            ))}

        </Grid>
      </Container>
    </MKBox>
  );
}

export default OrdersSection;
