/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKProgress from "components/MKProgress";


// Material Kit 2 React components
import TransparentAlbumCard from "components/Cards/AlbumCards/TransparentAlbumCard";
import TransparentImageCard from "components/Cards/AlbumCards/TransparentImageCard";

import React, { useState, useEffect, useMemo, useRef,} from "react";
import {useDropzone} from 'react-dropzone';
import { v4 as uuid } from 'uuid';


import { createPost } from 'graphql/mutations';
import { dynamicSort } from "assets/utils";





import { getAlbum } from 'assets/customgraphql/queries';
import { listAlbums, listPosts } from 'graphql/queries'
import { listAllAlbums } from 'assets/customgraphql/queries';


import { useParams } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import DynamicTable from "./dynamictable";
import useDynamicTable from "./useDynamicTable";
import WrappedMaterialReactTable from './WrappedMaterialReactTable';

//import DynamicTable from "./dynamictable";
import { generateColumnsFromSchema } from './utils';
import { func } from "prop-types";
import { subscribeAlbum, notifySubscribers } from "assets/utils";
import { generateClient } from "aws-amplify/api";
import { uploadData } from "aws-amplify/storage";


//Dropzone css - figure out how to eliminate this
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};



function AdminSection() {
    //const { id } = useParams();
    const [albumlist, updateAlbumList] = useState([]);
    const [albumname, updateAlbumName] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
    const [selectedAlbum, updateSelectedAlbum] = useState([]);
    const [watermarkInput, updateWatermarkInput] = useState('');

    function onSelectAlbum(e) {
      //e.persist();
      console.log(e);
      console.log(e.target.value);
      updateSelectedAlbum(e.target.value);
    }

    function onWatermarkInput(e) {
      //e.persist();
      console.log(e);
      console.log(e.target.value);
      updateWatermarkInput(e.target.value);
    }
  


  async function fetchAlbums() {
    const APIClient = generateClient()
    let albumData = await APIClient.graphql({ query: listAllAlbums });
    let albumsArray = albumData.data.listAlbums.items;

    Promise.all([albumData]).then((values) => {
      //console.log("All promises returned, running updateAlbumList");
      updateAlbumList(albumsArray);
      //console.log(albumsArray);
    }    
    )    
  }

  
    // Stuff for the dropzone here
    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps} = useDropzone({
      accept: {
        'image/*': []
      },
      onDrop: acceptedFiles => {
        acceptedFiles.sort(dynamicSort("-lastModified"));
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      }
    });

    const thumbs = files.map(file => (
      <>
      <Grid item style={thumb} key={file.name} flexDirection="column">
        <Grid item style={thumbInner} height="80%">
          <img
            src={file.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => { URL.revokeObjectURL(file.preview) }}
            />
        </Grid>
        <Grid item height="20%" marginTop={"1rem"}>
        <MKProgress value={file.progress} />
        </Grid>
      </Grid>
       </>
    ));

    useEffect(() => {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

useEffect(() => {
  fetchAlbums();
},[] )



function generateFileName(file) {
  const fileExtPosition = file.name.search(/.png|.jpg|.gif/i);
  const firstHalf = file.name.slice(0, fileExtPosition);
  const secondHalf = file.name.slice(fileExtPosition);
  const postUuid = uuid();
  const fileName = firstHalf + "_" + postUuid + secondHalf;
  console.log(fileName);
  const image = { fileInfo: file, name: fileName, uuid: postUuid};
  return(image);

}

function retNewArr(input,obj,index){
  let newinput = [...input];
  newinput[index] = obj;
  return newinput;
}


async function bulkUpload() {
  const APIClient = generateClient({authMode: "userPool"});
  console.log(files);
  var uploadArray = [];

  files.forEach(file => uploadArray.push(file));

  for (let [i, file] of uploadArray.entries()) {

    const fileObj = generateFileName(file);
    const postInfo = {
      albumPhotosId: selectedAlbum,
      image: fileObj.name,
      id: fileObj.uuid,
      watermark: watermarkInput,
      //enabled: false
    };
  
    
    file.progress = 5;
    setFiles(retNewArr(files,file,i));

    file.progress += 5;
    setFiles(retNewArr(files,file,i));
    // upload the file
    console.log(postInfo);
    console.log(APIClient);
    await APIClient.graphql({
      query: createPost,
      variables: { input: postInfo },
      //authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then(result => {
      file.progress += 20;
      setFiles(retNewArr(files,file,i));

  })

  const handleUpload = async (path, data) => {
    const operation = uploadData({
      path: `public/${path}`,
      data
    });

  const result = await operation.result;
}


  await handleUpload(fileObj.name, fileObj.fileInfo).then(result => {
    file.progress += 70;
    setFiles(retNewArr(files,file,i));
  });
}
  

}
    
//console.log(albumlist.map(({ id, name }) => ({ id, 'label':name})))
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item >
          <MKTypography variant="h3" mb={6}>
            Upload images
          </MKTypography>
          <MKBox p={12}>
              <MKTypography variant="body2" color="text" mb={3}>
                Instructions
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <MKInput
                      key="AlbumSelect"
                      variant="standard"
                      label="Select Album"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      select
                      defaultValue=''
                      onChange={onSelectAlbum}
                      //options={albumlist.map(({ id, name }) => ({ id, 'label':name}))} 
                      //Add options here somewhere
                      >
                        <MenuItem key="selectBlank" value=''></MenuItem>

                        {albumlist.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
          ))}

                    </MKInput>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      key="WatermarkInput"
                      variant="standard"
                      label="Custom Watermark?"
                      fullWidth
                      defaultValue=''
                      onChange={onWatermarkInput}
                      //options={albumlist.map(({ id, name }) => ({ id, 'label':name}))} 
                      //Add options here somewhere
                      >
                        <MenuItem key="selectBlank" value=''></MenuItem>

                        {albumlist.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
          ))}

                    </MKInput>
                  </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item>
                          <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                          </div>
                          <Grid item>

                            {thumbs}

                          </Grid>
                    </Grid>
                    
                  </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton variant="gradient" color="info" onClick={bulkUpload}>
                    Start Upload
                  </MKButton>
                  <MKButton variant="gradient" color="info" onClick={subscribeAlbum}>
                    TestSubscribe
                  </MKButton>
                  <MKButton variant="gradient" color="info" onClick={() => notifySubscribers(selectedAlbum)}>
                    SendNotification
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
            {/* <MKBox p={12}>

          <MKTypography variant="h3" mb={6}>
            Posts
          </MKTypography>
          <WrappedMaterialReactTable queryName="listPosts" />
            </MKBox> */}
          {/* <MaterialReactTable columns={postColumns} data={postData} /> */}
        </Grid>
        <Grid container spacing={3}>
          
          {albumlist.map((item) => (
            <Grid item xs={12} lg={6} key={item.id} >
              <TransparentImageCard
                image={item.imageurl}                
                title=""
                description=""
                action={{
                  type: "internal",
                  route: "/post/" + item.id,
                  color: "info",
                  label: "",
                  description: "",

                }}
              />
              
            </Grid>

          ))}

        </Grid>
      </Container>
    </MKBox>
  );
}

export default AdminSection;
