/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import AlbumSection from "pages/LandingPages/Album/sections/AlbumSection";
import AlbumLayout from "components/Layout/Album";
import { Link, useParams } from "react-router-dom";
import ImageSection from "../Image/sections/ImageSection";
import { Navigate } from "react-router-dom";

function Album() {
  const pagenum = useParams().pagenum;

  return (

    <>
      <AlbumLayout>
        {useParams().id && 
        pagenum ? <AlbumSection /> : <Navigate to="page/1" />
        }
      </AlbumLayout>
      {useParams().postid &&
        <ImageSection />
      }
    </>
  );
}

export default Album;
