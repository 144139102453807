/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import AlbumsSection from "pages/LandingPages/Albums/sections/AlbumsSection";
import AlbumLayout from "components/Layout/Album";

function Albums() {
  return (
    <>
      <AlbumLayout>
        <AlbumsSection />
      </AlbumLayout>
    </>
  );
}

export default Albums;
