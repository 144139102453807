/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Kit 2 React components
import MKBox from "components/MKBox";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";



import { useParams } from 'react-router-dom';

import { fetchPost } from "assets/utils";
import BuyImageModal from "layouts/sections/attention-catchers/modals/components/BuyImageModal";
import DownloadImageModal from "layouts/sections/attention-catchers/modals/components/DownloadImageModal";




function ImageSection() {
  const { id, postid, pagenum = 1 } = useParams();
  const [postinfo, updatePostInfo] = useState([]);
  

  useEffect(() => {
    fetchPost(postid).then(result => {
      if (result.prices) {
        result.prices.map((product) => {
          console.log(product);
          product.name = "Photo";
          product.id = "post_" + postid + "_" + product.price_name;
          product.price = product.unit_amount;
          product.currency = "AUD";
        })
      }


      updatePostInfo(result);
    });
    //updatePostInfo(postInfo);
  }, [postid]);


  return (
    <MKBox component="section" py={0}
    position="fixed"
    top={0}
    zIndex="10"
    bgColor="white"
    borderRadius="xl"
    shadow="lg"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height="100%"
    width="100%"
    
    
    ><MKBox
    display="flex"
    zIndex="2"
    position="absolute"
    top={"4rem"}
    // right={10}
    width="80%"
    justifyContent="space-between"
    >
        {/* {postinfo.prices ? (postinfo.prices.map((product) => (
          console.log(product),
          product.name = "Photo",
          product.id = "post_" + postid + "_" + product.price_name,
          product.price = product.unit_amount,
          product.currency = "AUD",
          <MKButton
            m="auto"
            zindex="9"
            variant="gradient"
            color="info"
            onClick={() => { addItem(product); }}
            ><CloudDownloadIcon icononly="true"> Download </CloudDownloadIcon></MKButton>
        )
        )) : console.log("No Prices")} */}

        {postinfo.prices ? <>
        <BuyImageModal postid={postid} prices={postinfo.prices} />
          </>
         : console.log("No prices")}
        <DownloadImageModal postinfo={postinfo} />
      </MKBox>
      <MKBox
        display="flex"
        zIndex="2"
        position="absolute"
        bottom={10}
        right={10}
      >
        <img src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png" alt="Creative Commons License" />
      </MKBox>

      <MKBox
        sx={{
          backgroundImage: `url(${postinfo.imageurl})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat"
        }}

        borderRadius="lg"
        shadow="md"
        width="100%"
        height="100%"
        position="relative"
        zIndex={1}
      >

        <Link to={useParams().id ? `/album/${id}/page/${pagenum}` : '/albums'}>
          <MKBox
            height="100%"
            width="100%"
          >
          </MKBox>
        </Link>


      </MKBox>

    </MKBox>
  );
}

export default ImageSection;
