/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentAlbumCard from "components/Cards/AlbumCards/TransparentAlbumCard";

import React, { useState, useEffect } from "react";

import { listAllAlbums } from 'assets/customgraphql/queries';
import { dynamicSort } from "assets/utils";
import { getUrl } from "aws-amplify/storage";
import { generateClient } from 'aws-amplify/api';


function AlbumsSection() {

    const [albumlist, updateAlbumList] = useState([]);
    useEffect(() => {
      fetchAlbums();
    }, []);
    useEffect(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
     }, []);
   
    async function fetchAlbums() {
      const APIClient = generateClient();
      let albumData = await APIClient.graphql({ query: listAllAlbums });
      let albumsArray = albumData.data.listAlbums.items;
      let promisearray = [];
      await albumsArray.map((album) => (
        album.photos.items.map( (image) => {
            let promise = getUrl({ 
              path: "public/processed/tn-" + image.image
             }).then(result => {
                image.imageurl = result.url.href
                //console.log(result)
            });
            promisearray.push(promise);
        }
        )
      ))
      albumsArray.sort(dynamicSort("-createdAt"));
      //console.log(promisearray);
      Promise.all(promisearray).then((values) => {
          //console.log("All promises returned, running updateAlbumList");
          updateAlbumList(albumsArray);
        }        
      )
  
      //updateAlbumList(albumsArray);
    }
    
  
  return (
    <MKBox component="section" py={2}>
      <Container >
        <Grid container item>
          <MKTypography variant="h3" mb={3} key="sectionHeader">
            All albums ever posted. Newest at top
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          
          {albumlist.map((item) => (
            //<Grid item xs={12} lg={6}>
            <Grid item xs={12} key={item.id}>
              <TransparentAlbumCard
                albumid={item.id}
                image=""
                images={item.photos.items.map((item)=>(item.imageurl))}
                title={item.name}
                action={{
                  type: "internal",
                  route: "/album/" + item.id + "/page/1",
                  color: "info",
                  label: "",
                  description: "",

                }}
              />
              
            </Grid>

          ))}

        </Grid>
      </Container>
    </MKBox>
  );
}

export default AlbumsSection;
