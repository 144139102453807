import { gql } from 'graphql-tag';

const getRootQueryName = (query) => {
  const ast = gql(query);
  const rootField = ast.definitions.find(
    (definition) => definition.kind === 'OperationDefinition' && definition.operation === 'query'
  );
  return rootField.name.value;
};

const extractColumnsFromSelection = (selection, columnsSet) => {
    if (selection.kind === 'Field') {
      if (selection.selectionSet) {
        //console.log('Entering nested Field selectionSet');
        return extractColumnsFromSelectionSet(selection.selectionSet.selections, columnsSet);
      } else {
        //console.log('Field:', selection.name.value);
        const columnName = selection.name.value;
        if (columnName !== 'nextToken' && !columnsSet.has(columnName)) {
          columnsSet.add(columnName);
          return [
            {
              Header: columnName,
              accessorKey: columnName,
              // You can add more properties to customize the column
            },
          ];
        }
      }
    } else if (selection.kind === 'InlineFragment') {
      //console.log('Entering InlineFragment');
      return extractColumnsFromSelectionSet(selection.selectionSet.selections, columnsSet);
    }
    return [];
  };
  
  const extractColumnsFromSelectionSet = (selections, columnsSet) => {
    const columns = [];
    for (const selection of selections) {
      columns.push(...extractColumnsFromSelection(selection, columnsSet));
    }
    return columns;
  };
  
  export const generateColumnsFromSchema = (query) => {
    if (!query) return [];
  
    // Find the correct root query field name
    const typeName = getRootQueryName(query);
  
    // Parse the query string into its AST representation using gql tag
    const ast = gql(query);
  
    // Find the correct query definition based on typeName
    const queryDefinition = ast.definitions.find(
      (definition) =>
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'query' &&
        definition.name.value === typeName
    );
  
    if (!queryDefinition || !queryDefinition.selectionSet || !queryDefinition.selectionSet.selections)
      return [];
  
    const selections = queryDefinition.selectionSet.selections;
    const columnsSet = new Set();
  
    // Start extracting columns from the top-level selections
    return extractColumnsFromSelectionSet(selections, columnsSet);
  };
  