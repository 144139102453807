// Layout.js
import React from 'react';
import MKBox from "components/MKBox";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import routes from "routes";
import Footer from "components/Footers/SimpleFooter";


const AlbumLayout = ({ children }) => {
    return (
      <>
        {/* Common layout components */}
        <MKBox position="fixed" top="0.5rem" width="100%" zIndex="10" >
          <DefaultNavbar routes={routes} relative />
        </MKBox>
        <MKBox
          justifyContent="center"
          display="flex"
        >

          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 11, sm: 10, md: 11 }}
            mb={{ xs: 4, sm: 3, md: 4 }}
            mx={3}
          >
            {children}
            <Footer />
          </MKBox>
        </MKBox>
      </>
    );
  };
export default AlbumLayout;