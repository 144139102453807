const personalUseSpiels = [
  `Download these rad shots and let your profile pic scream 'trail warrior.'
  Share the dirt-filled joy on socials, but here's the deal – these pixels are for personal peaks, not commercial slopes.
  So, hit those trails, embrace the adrenaline, and let the world see your mountain biking saga unfold!
  Don't forget to tag!`,
  `Unleash these extreme shots on your profile pic or socials, but remember – it's a personal thrill ride only. 
  No commercial usage allowed! Please tag us if you share`,
  `Your free downloads are here to elevate your digital game. Personalize your profile pic, flaunt your biking prowess on socials, but remember – no commercial detours allowed on this trail!`,
  `Shred the pixels! Free downloads for your personal playground. 
  Revamp your profile pic, share the mountain biking glory on socials, but hit the brakes on any commercial aspirations!`,
  `Gear up for a digital downhill! 
  Free downloads ready to fuel your mountain biking spirit. 
  Use them to conquer your profile pic, share the rush on socials, but hit the brakes on commercial slopes – personal use only!`,
]

export default personalUseSpiels;
