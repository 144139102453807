/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components

import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

//import { listOrders, getOrder, getOrderWithCsId, getPost, fetchLineItemDownloadLink } from "graphql/queries";
import { getOrder, getOrderWithCsId } from "graphql/queries";
import { processLineItem, whichAuthMode } from "assets/utils";
import MKButton from "components/MKButton";
import { OrderComponent } from "../sections/OrderComponent";
import { generateClient } from "aws-amplify/api";


function OrderSection() {

  const { id, csId } = useParams();
  const [order, updateOrder] = useState([]);

  useEffect(() => {
    fetchOrder();
  }, [ id, csId ]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  async function fetchOrder() {
    const APIClient = generateClient();

    console.log("fetchorder called");

    let query = getOrder;
    let queryName = "getOrder"
    let variables = { id: id };
    let authMode = await whichAuthMode();
    let orderData;
    let order;
    console.log(`id is: ${id}`)
    console.log(`csId is: ${csId}`)
    if (csId) {
      query = getOrderWithCsId;
      queryName = "getOrderWithCsId";
      variables.csId = csId;
    }


    try {
      orderData = await APIClient.graphql({ query: query, variables: variables, authMode: authMode });
      order = orderData.data[queryName];
      console.log(orderData);
    } catch ({ errors, data }) {
    if (data) {
      console.warn("GraphQL Query returned data with errors", errors);
      console.log(data);
      order = data[queryName];
    } else {
      console.error("Unhandled error", errors);
    }
    }
    let promisearray = [];
    console.log(order);
    await order.lineItems.map((item) => {
        promisearray.push(processLineItem(item));
      })

    Promise.all(promisearray).then((values) => {
      updateOrder(order);
      console.log(order);
    })
  }



  return (
    <MKBox component="section" py={0} px={"1rem"}>
      <Container >
        <Grid container item>
          <MKTypography variant="h3" mb={3} key="sectionHeader">
            Your Order
          </MKTypography>
        </Grid>
        <OrderComponent order={order} />
      </Container>
    </MKBox>
  );
}

export default OrderSection;
