/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import { Link } from '@mui/material';
import { Instagram } from '@mui/icons-material';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ContactLayout from "components/Layout/Contact";




function Contact() {
  return (
    <>
<ContactLayout>
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
              >
              <MKTypography variant="h3" color="white">
                Contact
              </MKTypography>
            </MKBox>

            <MKBox p={3} className="info info-horizontal">
              <MKBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="icon icon-primary">
                <i className="material-icons">camera_alt</i>
              </MKBox>
              <MKBox className="description">
                <MKTypography variant="h5" className="info-title">
                  Snapping the Action
                </MKTypography>
                <MKTypography className="description">
                  I'm Shan. I ride bikes, and I take photos. <Link href="/personal-use">My images are free to download for personal use.</Link>
                </MKTypography>
              </MKBox>
            </MKBox>
            <MKBox p={3} className="info info-horizontal">
              <MKBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="icon icon-primary">
                <i className="material-icons">photo</i>
              </MKBox>
              <MKBox className="description">
                <MKTypography variant="h5" className="info-title">
                  Found The Perfect Shot?
                </MKTypography>
                <MKTypography className="description">
                  You've scrolled for hours and you've finally found it. One to put on the wall. This is more than just your next profile pic. @ShanMTB snapped you causing a ruckus on the trail, and this one has to go straight to the poolroom.
                </MKTypography>
              </MKBox>
            </MKBox>
            <MKBox p={3} className="info info-horizontal">
              <MKBox className="description">
                <MKTypography variant="h5" className="info-title">
                  Unleash the Full Experience
                </MKTypography>
                <MKTypography className="description">
                  The freebies are great, but the premium tier content is where it's at. High-res, custom edits, and no watermarks!
                  Ready to take it up a notch?
                </MKTypography>
              </MKBox>
            </MKBox>
            <MKBox p={3} className="info info-horizontal">
              <MKBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="icon icon-primary">
                <i className="material-icons">chat_bubble</i>
              </MKBox>
              <Link href="https://www.instagram.com/shanmtb/" className="btn btn-primary btn-round">
                <MKBox className="description">
                  <MKTypography className="description">
                    Slide into my DMs on Insta to discuss purchasing prints or full-res images for personal or commercial use, or just to buy me a beer!
                  </MKTypography>
                </MKBox>
                <MKBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="icon icon-primary">
                  <Instagram />
                </MKBox>
              </Link>
            </MKBox>
        </ContactLayout>
    </>
    );
}

export default Contact;
