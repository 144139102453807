/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

//import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Album from "layouts/pages/landing-pages/album";
import AlbumVids from "layouts/pages/landing-pages/albumvids";
import Admin from "layouts/pages/landing-pages/admin";
import { Order } from "pages/LandingPages/Orders";


// Material Kit 2 React routes
import routes from "routes";
import AlbumGroup from "pages/LandingPages/AlbumGroup";

// Stripe integration and cart
import { CartProvider } from "use-shopping-cart";
import StripeCancel from "pages/LandingPages/Stripe/stripe";
import StripeSuccess from "pages/LandingPages/Stripe/stripe";
const STRIPE_PUBLISHABLE_KEY = (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY : process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY )


export default function App() {


  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.route.match(".*album/.*")) {
          //("Ignored album route");
          return null;
        }
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CartProvider cartMode="checkout-session" stripe={STRIPE_PUBLISHABLE_KEY} currency="AUD">
        <CssBaseline />
        <Routes>
          {getRoutes(routes)}
          {/* <Route path="/presentation" element={<Presentation />} /> */}

          <Route path='/#/album/:id' element={<Album />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/album/:id" element={<Album />} >
            <Route path="page/:pagenum" element={<Album />}>
              <Route path="post/:postid" element={<Album />} />
            </Route>
            <Route path="post/:postid" element={<Album />} />
          </Route>
          <Route path="/albumvids/:id" element={<AlbumVids />}>
          <Route path="page/:pagenum" element={<Album />}>
              <Route path="post/:postid" element={<Album />} />
            </Route>
          </Route>

            
          <Route path="/albumvids" element={<AlbumVids />} />
          <Route path="/#/post/:postid" element={<Album />} />
          <Route path="/post/:postid" element={<Album />} />
          <Route path="/albumGroup/:id" element={<AlbumGroup /> } />
          <Route path="/order/:id" element={<Order />}>
            <Route path="csid/:csId" element={<Order />} />
          </Route>
          <Route path="/order" element={<Order />} />
          <Route path="/stripe/success/:id/:csId" element={<StripeSuccess />} />

          {(window.location.hash !== '') && console.log("hash replace triggered") & <Route path="*" element=<Navigate to={window.location.hash.replace('#', '')} /> />}
          <Route path="*" element={<Navigate to="/albums" />} />
        </Routes>
      </CartProvider>
    </ThemeProvider>
  );
}