/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentAlbumCard from "components/Cards/AlbumCards/TransparentAlbumCard";
import TransparentImageCard from "components/Cards/AlbumCards/TransparentImageCard";

import { API, Storage } from 'aws-amplify';
import React, { useState, useEffect, useMemo, useRef,} from "react";


import { getAlbum } from 'assets/customgraphql/queries';
import { listAlbums, listPosts } from 'graphql/queries'

import { useParams } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import DynamicTable from "./dynamictable";
import useDynamicTable from "./useDynamicTable";
import WrappedMaterialReactTable from './WrappedMaterialReactTable';

//import DynamicTable from "./dynamictable";
import { generateColumnsFromSchema } from './utils';





function AdminSection() {
    //const { id } = useParams();
    const [albumlist, updateAlbumList] = useState([]);
    const [albumname, updateAlbumName] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
    // useEffect(() => {
    //   fetchAlbums(id);
    // }, [id]);


    
    
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item >
          <MKTypography variant="h3" mb={6}>
            Albums
          </MKTypography>
          <WrappedMaterialReactTable queryName="listAlbums" />
          <MKTypography variant="h3" mb={6}>
            Posts
          </MKTypography>
          <WrappedMaterialReactTable queryName="listPosts" />
          {/* <MaterialReactTable columns={postColumns} data={postData} /> */}
        </Grid>
        <Grid container spacing={3}>
          

        </Grid>
      </Container>
    </MKBox>
  );
}

export default AdminSection;
