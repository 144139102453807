export const listAllAlbums = /* GraphQL */ `
query ListAlbums(
  $filter: ModelAlbumFilterInput
  $limit: Int
  $nextToken: String
) {
  listAlbums(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
        photos(limit: 4, filter: {enabled: {eq: true}}) {
        items {
          id
          image
        }
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;


export const listLatestAlbums = /* GraphQL */ `
query ListAlbums(
  $filter: ModelAlbumFilterInput
  $limit: Int
  $nextToken: String
) {
  listAlbums(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;

export const listLatestAlbumGroups = /* GraphQL */ `
query ListAlbumGroups(
  $filter: ModelAlbumGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listAlbumGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;



export const getAlbum = /* GraphQL */ `
  query GetAlbum($id: ID!) {
    getAlbum(id: $id) {
      id
      name
      photos (limit: 1000, filter: {enabled: {eq: true}}){
        items {
          id
          image,
          plates,
          createdAt
        }
        nextToken
      }
      videos (limit: 1){
        items {
          id
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const getAlbumVids = /* GraphQL */ `
  query GetAlbum($id: ID!) {
    getAlbum(id: $id) {
      id
      name
      videos (limit: 1000){
        items {
          id
          filename
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;


export const getAlbumGroup = /* GraphQL */ `
  query GetAlbumGroup($id: ID!) {
    getAlbumGroup(id: $id) {
      name
      albums{
          items
        {
      id
      name
      photos (limit: 4, filter: {enabled: {eq: true}}){
        items {
          id
          image,
          plates,
          createdAt
        }
        nextToken
      }
      createdAt
      updatedAt
        }
      }
    }
  }
`;
