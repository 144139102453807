/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import OrdersSection from "pages/LandingPages/Orders/sections/OrdersSection";
import OrderSection from "./sections/OrderSection";
import AlbumLayout from "components/Layout/Album";
import { withAuthenticator } from "@aws-amplify/ui-react";

function Orders() {
  return (
    <>
      <AlbumLayout>
        <OrdersSection />
      </AlbumLayout>
    </>
  );
}

export function Order() {

  return (
    <>
      <AlbumLayout>
        <OrderSection />
      </AlbumLayout>
    </>
  )
}


export default withAuthenticator(Orders);
