
import { useEffect, useState } from 'react';

import { generateColumnsFromSchema } from './utils'; 
import * as queries from 'graphql/queries'
import { generateClient } from 'aws-amplify/api';

const useDynamicTable = (queryName) => {
    const [columns, setColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const query = queries[queryName];
      //console.log("Query: " + query)
  
      const match = query.match(/query\s+(\w+)/);
      const typeName = query.match(/query\s+(\w+)/)?.[1] || 'UnknownQuery';
      
      //console.log("typeName: " + typeName)

      const generatedColumns = generateColumnsFromSchema(query, typeName);
      setColumns(generatedColumns);
      //console.log("GeneratedColumns")
      //console.log(generatedColumns)
  
      fetchData();
    }, [queryName]);
  
    const fetchData = async () => {
      const APIClient = generateClient();
      try {
        const { data } = await APIClient.graphql({ query: queries[queryName], authMode: "AMAZON_COGNITO_USER_POOLS", variables: {limit: 200} });
        const tableData = data[queryName].items;
        setTableData(tableData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      }
    };
  
    return { columns, tableData, loading, error };
  };
  
  export default useDynamicTable;