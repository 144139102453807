export const personalUseReminder = `Personal use license unlocked!
No commercial use, resale, or profit derivation. Enjoy responsibly!`

export const personalUseLicense = `Terms of Use and Image Availability Disclaimer

By purchasing and downloading images under our Personal Use License, you agree to the terms and conditions outlined in this license agreement.

1. Severability:
If any provision of this license agreement is found to be invalid, illegal, or unenforceable, the remaining provisions will continue to be valid and enforceable to the fullest extent permitted by law. The parties agree to replace any invalid or unenforceable provision with a valid and enforceable provision that most closely aligns with the intent of the original provision.

2. Personal Use Only:
The images provided are intended for personal use only. This includes but is not limited to use on social media, personal printing, sharing with relatives, and incorporating into personal digital or physical artworks.

3. Derivative Works:
You are allowed to create derivative works based on the image. However, any derivative works must also adhere to the same terms outlined in this agreement.

4. Non-Transferable Licence:
The licence granted with this purchase is non-transferable. You may not sell, lease, loan, transfer, or otherwise make the image available to third parties, whether for profit or otherwise.

5. Exclusion of Profit Generation:
Any use of the image for the generation of profit, including but not limited to commercial ventures, advertising, promotions, merchandise, or any other revenue-generating activities, is strictly prohibited.

6. Attribution:
While attribution is not required, it is appreciated. If you choose to share the image on social media or other platforms, a mention or tag acknowledging the source is a courteous gesture.

7. Copyright and Ownership:
The copyright and ownership of the image remain with the original creator or rights holder. Purchasing this image grants you a licence for personal use but does not transfer ownership or copyright.

8. Disclaimer Regarding Image Availability:
Please note that there is no guarantee of perpetual availability for downloaded images. While we strive to provide a reliable service, factors such as technical issues or maintenance may temporarily affect accessibility. It is recommended to promptly download and securely store purchased images. Your understanding is appreciated.

`
export const commercialUseReminder =`Commercial license unlocked. 
Perfect for promotions, ads, and more. 
Media outlets and News organizations: remember proper attribution. `

export const commercialUseLicense =`Terms of Use and Image Availability Disclaimer

By purchasing and downloading images under our Commercial Use License, you agree to the terms and conditions outlined in this license agreement.

1. Severability:
If any provision of this license agreement is found to be invalid, illegal, or unenforceable, the remaining provisions will continue to be valid and enforceable to the fullest extent permitted by law. The parties agree to replace any invalid or unenforceable provision with a valid and enforceable provision that most closely aligns with the intent of the original provision.

2. Commercial Use Permitted:
The Commercial Use License grants you the right to use the images for commercial purposes, including but not limited to advertising, promotions, merchandise, and other revenue-generating activities.

3. Derivative Works:
You are allowed to create derivative works based on the image. However, any derivative works must also adhere to the same terms outlined in this agreement.

4. Non-Transferable Licence:
The license granted with this purchase is intended for your personal and/or business use. It is non-transferable, meaning you may not sell, lease, loan, or directly transfer the license to third parties. However, you are permitted to use the licensed image within the scope of your commercial activities, ensuring it remains within the context of your usage.

5. Exclusion of Certain Uses:
While commercial use is permitted, certain uses are strictly prohibited. This includes the creation or distribution of offensive, defamatory, or unlawful content.

6. Attribution (Mandatory for News Organisations):
News organisations and media outlets are required to provide proper attribution when using the licensed images. Attribution must be prominently displayed in connection with the image and include the following information: shanmtb.com and the name of the original creator or rights holder.

7. Copyright and Ownership:
The copyright and ownership of the image remain with the original creator or rights holder. Purchasing this Commercial Use License grants you a license for specific commercial purposes within your social media context but does not transfer ownership or copyright.

8. Disclaimer Regarding Image Availability:
Please note that there is no guarantee of perpetual availability for downloaded images. While we strive to provide a reliable service, factors such as technical issues or maintenance may temporarily affect accessibility. It is recommended to promptly download and securely store purchased images. Your understanding is appreciated.
`

