/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";


// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { subscribeAlbum } from "assets/utils";

function TransparentAlbumCard({ image, title, description = "", action, images = [], albumid }) {
  const cardActionStyles = {
    display: "flex",
    alignItems: "center",
    width: "max-content",

    "& .material-icons, .material-icons-round,": {
      transform: `translateX(2px)`,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
    {
      transform: `translateX(6px)`,
    },
  };
  //console.log(images);

  const imageTemplate = (
    <MKBox position="relative" borderRadius="lg" key={image.id + title}>
      <Grid container alignItems={"center"} key={image.id + "wrapper"}>

        {images.map(image => (

          <>
            <Grid item xs={3} key={image.id}>
              <MKBox
                position="relative" mx={0.5} key={image.id + "container"}>
                <MKBox
                  key={image.id + "fg"}
                  component="img"
                  src={image}
                  alt={title}
                  borderRadius="lg"
                  shadow="md"
                  width="100%"
                  position="relative"
                  zIndex={1} />
                <MKBox
                  key={image.id + "bg"}
                  borderRadius="lg"
                  shadow="md"
                  width="100%"
                  height="100%"
                  position="absolute"
                  left={0}
                  top={0}
                  sx={{
                    backgroundImage: `url(${image})`,
                    transform: "scale(0.94)",
                    filter: "blur(12px)",
                    backgroundSize: "cover",
                  }} />
              </MKBox>
            </Grid>
          </>
        ))}
      </Grid>
    </MKBox>
  );

  if (images.length === 0) {
    return (
      <Card
        sx={{
          background: "transparent",
          boxShadow: "none",
          overflow: "visible",
          borderWidth:"0.2rem",
          padding: "1rem",
          }}
      >

        <MKBox className="description">
          <MKTypography variant="h5" className="info-title">
            Coming Soon - {title}
          </MKTypography>
          <MKTypography className="description">
          {/* Itching to feast your eyeballs on the symphony of pixels already? It's not ready yet - but if you want to be the first to know... */}
          {/* I'm not just moving pixels here; I'm conducting a digital orchestra. 
          Think hundreds of gigabytes dancing their way into the cloudy goodness of the interwebs. 
          It's like trying to teach a unicorn to skateboard – totally doable, but it takes a bit of 
          magic and time. If you want to be one of the first to know, nose-bonk the button below 
          and get ready to ride the wave of awesomeness the moment it goes live. 
          You already know it'll be worth the wait. */}
          </MKTypography>
            <MKButton
              zindex="9"
              variant="gradient"
              color="info"
              target="_blank"
              onClick={() => subscribeAlbum(albumid)}
            >Enable notifications for {title} (Experimental Feature)</MKButton>

        </MKBox>
      </Card>

    )

  }


  return (
    <Card
      sx={{
        background: "transparent",
        boxShadow: "none",
        overflow: "visible",
        borderWidth:"0.2rem",
        padding: "1rem",

      }}
    >
      <MKBox p={0} >
        {action.type === "internal" ? (
          <Link to={action.route} sx={cardActionStyles}>
            <MKTypography variant="h5" gutterBottom>
              {title}
            </MKTypography>
          </Link>
        ) : (
          <MuiLink href={action.route} target="_blank" rel="noreferrer" sx={cardActionStyles}>
            <MKTypography variant="h5" gutterBottom>
              {title}
            </MKTypography>
          </MuiLink>
        )}
        {/* <MKTypography variant="body2" component="p" color="text" mb={3}>
          {description}
        </MKTypography> */}
      </MKBox>
      {action.type === "internal" ? (
        <Link to={action.route}>{imageTemplate}</Link>
      ) : (
        <MuiLink href={action.route} target="_blank" rel="noreferrer">
          {imageTemplate}
        </MuiLink>
      )}
    </Card>
  );
}

// Typechecking props for the TransparentAlbumCard
TransparentAlbumCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  albumid: PropTypes.string.isRequired,
  description: PropTypes.string,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "inherit",
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "text",
    ]).isRequired,
  }).isRequired,
};

export default TransparentAlbumCard;
