/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Link } from '@mui/material';
import { Instagram } from '@mui/icons-material';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ContactLayout from "components/Layout/Contact";

function PersonalUse() {
  return (
    <>
      <ContactLayout>

        <MKBox
          variant="gradient"
          bgColor="info"
          coloredShadow="info"
          borderRadius="lg"
          p={2}
          mx={2}
          mt={-3}
          >
          <MKTypography variant="h3" color="white">
            Personal Use
          </MKTypography>
        </MKBox>

        <MKBox p={3} className="info info-horizontal">
          <MKBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="icon icon-primary">
            <i className="material-icons">camera_alt</i>
          </MKBox>
          <MKBox className="description">
            <MKTypography variant="h5" className="info-title">
              Keep it Fair
            </MKTypography>
            <MKTypography className="description">
              "Personal Use" means enjoying my images like a stash of chips – share 'em with your mates, set 'em as your phone wallpaper, or even print 'em out to send to your rellos. And hey, if you're itching to flaunt your epicness, feel free to share them on Insta, but remember, this epic collection of pixels is exclusively for your own joy and not for spinning any profit wheels!
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox p={3} className="info info-horizontal">
        <MKBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="icon icon-primary">
            <i className="material-icons">local_offer</i>
          </MKBox>
          <MKBox className="description">
            <MKTypography variant="h5" className="info-title">
              Don't forget to tag!
            </MKTypography>
            <MKTypography className="description">
              Oh, and speaking of Insta, if you decide to share my shots, don't forget to @ me – it puts a smile on my face and it helps others find my collection!
              If you're chasing some light reading, you can always dive into the full legalese at <Link href="https://creativecommons.org/licenses/by-nc-sa/4.0/"> Creative Commons (CC) 4.0 BY-NC-SA.</Link>
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox p={3} className="info info-horizontal">
        <MKBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="icon icon-primary">
            <i className="material-icons">chat_bubble</i>
          </MKBox>
              <Link href="https://www.instagram.com/shanmtb/" className="btn btn-primary btn-round">
          <MKBox className="description">
            <MKTypography className="description">
                Slide into my DMs to discuss purchasing prints or full-res images for personal or commercial use, or just to buy me a beer!
              </MKTypography>
          </MKBox>
          <MKBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="icon icon-primary">
              <Instagram />
            </MKBox>

              </Link>             
        </MKBox>
      </ContactLayout>
    </>
  );
}

export default PersonalUse;
