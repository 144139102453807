/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import CircularProgress from '@mui/material/CircularProgress';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import React from "react";

import BuyImageModal from "../BuyImageModal";
import DownloadImageModal from "../DownloadImageModal";
import VideoJS from "pages/LandingPages/AlbumVids/sections/VideoJS";
import videojs from "video.js";

import { fetchPost } from "assets/utils";

function VideoModal({ show, albumlistpage, albumname, selectedpostindex, onClose, onNext, onPrev }) {
  const postinfo = albumlistpage[parseInt(selectedpostindex)];
  const [imageInfo, updateImageInfo] = useState(postinfo);
  console.log("Albumlistpage: ", albumlistpage);
  // console.log("selectedpostindex: ", parseInt(selectedpostindex));
  // console.log(postinfo);
  const [isLoading, updateIsLoading] = useState(true);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    //fluid: true,
    fill: true,
  };

  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    
    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });
    
    player.on('dispose', () => {
      videojs.log('player will dispose');
    });

    player.on('ready', () => {
      videojs.log('player is ready');
      updateIsLoading(false)
    });
  };


  useEffect(() => {

    console.log("postinfo is: ", postinfo);
    updateIsLoading(true);

    if (!postinfo) {
      // console.log("Postinfo was Null");
      return;
    } else if (Object.keys(postinfo).length === 0) {
      return;
    } else {

      const result = postinfo.tnlist;
      console.log(result);
      updateImageInfo(result);
    }
    // updatePostInfo(postInfo);
  }, [postinfo]);


  if (!postinfo || !imageInfo) {
    console.log("Postinfo was Null");
    return null;
  }


  return (
    <>

    {/* insert some css here... */}
    <style>
      {`
        .video-js {
          width: 100% !important;
          height: calc( 100vh - 22rem) !important;
        }
        
      `}
    </style>
    
      <Modal open={show} onClose={onClose} sx={{ display: "grid", placeItems: "center" }}>
          <MKBox
            position="relative"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          //maxWidth="550px"
          >
            <MKBox width="100%" justifyContent="end" padding="1em" display="flex">
          
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={onClose} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox position="relative" padding="0.25rem">

              {/* <MKBox
                sx={{
                  backgroundImage: `url(${postinfo.tnlist[0]})`,
                  transform: "scale(0.94)",
                  filter: "blur(12px)",
                  backgroundSize: "cover",
                }}


                borderRadius="lg"
                shadow="md"
                width="100%"
                height="100%"
                position="absolute"

              >
              </MKBox> */}
              {/* <MKBox
                component="img"
                src={postinfo.tnlist[0]}
                borderRadius="lg"
                shadow="md"
                maxWidth="100%"
                maxHeight="calc( 100vh - 10rem )"
                position="relative"
                zIndex={2}
                onLoad={() => updateIsLoading(false)}
                
              /> */}

              <MKBox 
                zIndex={3} 
                // width="100%"
                height="calc( 100vh - 20rem )"
                width="calc( 100vw - 10rem)"
                position="relative"
                >
              <VideoJS options={{ ...videoJsOptions, sources: [...postinfo.vidlist] }} onReady={handlePlayerReady} />
              </MKBox>

              { isLoading ? 
              <MKBox position="absolute" zIndex="3" top="50%" left="50%" >
              <CircularProgress />
              </MKBox> : null
              }
              {/* <MKBox width="100%" justifyContent="space-between" display="flex" position="absolute" top="0" height="100%" zIndex={3}>

                <MKBox height="100%" width="10rem" display="flex" justifyContent="center" flexDirection="column" onClick={onPrev}> 
                <KeyboardArrowLeftIcon fontSize="large" color="white" sx={{ cursor: "pointer" }} />
                </MKBox>
                <MKBox flex="1" onClick={onClose} />
                <MKBox height="100%" width="10rem" display="flex" justifyContent="center" flexDirection="column" alignItems="end" paddingRight="0.25rem" onClick={onNext}> 

                <KeyboardArrowRightIcon fontSize="large" color="white" sx={{ cursor: "pointer" }} />
                </MKBox>

              </MKBox> */}
            </MKBox>




            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>

              <MKBox display="flex" alignItems="center" justifyContent="center">
                {Array.isArray(imageInfo.prices) && imageInfo.prices.length ? <>
                  <BuyImageModal postid={imageInfo.id} prices={imageInfo.prices} image={imageInfo.image} />
                </>
                  : null}
                  {/* Need to quickly insert postinfo.vidlist[-1].src as imageurl with a spread to be used by DownloadImageModal */}
                <DownloadImageModal postinfo={{...postinfo,imageurl: postinfo.vidlist[0].src}} />
              </MKBox>

            </MKBox>

          </MKBox>
      </Modal>
    </>
  );
}

export default VideoModal;
