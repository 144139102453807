/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React pages
import Album from "pages/LandingPages/Album";
import Image from "pages/LandingPages/Image";
import { useParams } from 'react-router-dom';


export default function AlbumPage() {
  //if (useParams().postid) {return <Image /> }
  return <Album />;
}
