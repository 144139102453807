/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const startSfnExecution = /* GraphQL */ `
  mutation StartSfnExecution($input: StartSfnExecutionInput!) {
    startSfnExecution(input: $input) {
      executionArn
      startDate
      __typename
    }
  }
`;
export const executeStateMachine = /* GraphQL */ `
  mutation ExecuteStateMachine($input: executeStateMachineInput!) {
    executeStateMachine(input: $input) {
      name
      status
      input
      executionArn
      startDate
      stopDate
      output
      __typename
    }
  }
`;
export const createAlbumGroup = /* GraphQL */ `
  mutation CreateAlbumGroup(
    $input: CreateAlbumGroupInput!
    $condition: ModelAlbumGroupConditionInput
  ) {
    createAlbumGroup(input: $input, condition: $condition) {
      id
      name
      owner
      albums {
        items {
          id
          name
          owner
          photos {
            nextToken
            __typename
          }
          videos {
            nextToken
            __typename
          }
          createdAt
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          updatedAt
          albumGroupAlbumsId
          __typename
        }
        nextToken
        __typename
      }
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlbumGroup = /* GraphQL */ `
  mutation UpdateAlbumGroup(
    $input: UpdateAlbumGroupInput!
    $condition: ModelAlbumGroupConditionInput
  ) {
    updateAlbumGroup(input: $input, condition: $condition) {
      id
      name
      owner
      albums {
        items {
          id
          name
          owner
          photos {
            nextToken
            __typename
          }
          videos {
            nextToken
            __typename
          }
          createdAt
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          updatedAt
          albumGroupAlbumsId
          __typename
        }
        nextToken
        __typename
      }
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlbumGroup = /* GraphQL */ `
  mutation DeleteAlbumGroup(
    $input: DeleteAlbumGroupInput!
    $condition: ModelAlbumGroupConditionInput
  ) {
    deleteAlbumGroup(input: $input, condition: $condition) {
      id
      name
      owner
      albums {
        items {
          id
          name
          owner
          photos {
            nextToken
            __typename
          }
          videos {
            nextToken
            __typename
          }
          createdAt
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          updatedAt
          albumGroupAlbumsId
          __typename
        }
        nextToken
        __typename
      }
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlbum = /* GraphQL */ `
  mutation CreateAlbum(
    $input: CreateAlbumInput!
    $condition: ModelAlbumConditionInput
  ) {
    createAlbum(input: $input, condition: $condition) {
      id
      name
      owner
      photos {
        items {
          id
          image
          pubimage
          owner
          enabled
          plates
          watermark
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          createdAt
          updatedAt
          albumPhotosId
          __typename
        }
        nextToken
        __typename
      }
      videos {
        items {
          id
          filename
          owner
          enabled
          plates
          watermark
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          createdAt
          updatedAt
          albumVideosId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      updatedAt
      albumGroupAlbumsId
      __typename
    }
  }
`;
export const updateAlbum = /* GraphQL */ `
  mutation UpdateAlbum(
    $input: UpdateAlbumInput!
    $condition: ModelAlbumConditionInput
  ) {
    updateAlbum(input: $input, condition: $condition) {
      id
      name
      owner
      photos {
        items {
          id
          image
          pubimage
          owner
          enabled
          plates
          watermark
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          createdAt
          updatedAt
          albumPhotosId
          __typename
        }
        nextToken
        __typename
      }
      videos {
        items {
          id
          filename
          owner
          enabled
          plates
          watermark
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          createdAt
          updatedAt
          albumVideosId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      updatedAt
      albumGroupAlbumsId
      __typename
    }
  }
`;
export const deleteAlbum = /* GraphQL */ `
  mutation DeleteAlbum(
    $input: DeleteAlbumInput!
    $condition: ModelAlbumConditionInput
  ) {
    deleteAlbum(input: $input, condition: $condition) {
      id
      name
      owner
      photos {
        items {
          id
          image
          pubimage
          owner
          enabled
          plates
          watermark
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          createdAt
          updatedAt
          albumPhotosId
          __typename
        }
        nextToken
        __typename
      }
      videos {
        items {
          id
          filename
          owner
          enabled
          plates
          watermark
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          createdAt
          updatedAt
          albumVideosId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      updatedAt
      albumGroupAlbumsId
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      lineItems {
        name
        product
        productId
        priceData {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        __typename
      }
      csId
      enabled
      owner
      ttl
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      lineItems {
        name
        product
        productId
        priceData {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        __typename
      }
      csId
      enabled
      owner
      ttl
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      lineItems {
        name
        product
        productId
        priceData {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        __typename
      }
      csId
      enabled
      owner
      ttl
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      image
      pubimage
      owner
      enabled
      plates
      watermark
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      albumPhotosId
      __typename
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      image
      pubimage
      owner
      enabled
      plates
      watermark
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      albumPhotosId
      __typename
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      image
      pubimage
      owner
      enabled
      plates
      watermark
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      albumPhotosId
      __typename
    }
  }
`;
export const createVideo = /* GraphQL */ `
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      id
      filename
      owner
      enabled
      plates
      watermark
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      albumVideosId
      __typename
    }
  }
`;
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      id
      filename
      owner
      enabled
      plates
      watermark
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      albumVideosId
      __typename
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      id
      filename
      owner
      enabled
      plates
      watermark
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      albumVideosId
      __typename
    }
  }
`;
export const createWebPushAlbumSubscription = /* GraphQL */ `
  mutation CreateWebPushAlbumSubscription(
    $input: CreateWebPushAlbumSubscriptionInput!
    $condition: ModelWebPushAlbumSubscriptionConditionInput
  ) {
    createWebPushAlbumSubscription(input: $input, condition: $condition) {
      id
      albumID
      album {
        id
        name
        owner
        photos {
          items {
            id
            image
            pubimage
            owner
            enabled
            plates
            watermark
            createdAt
            updatedAt
            albumPhotosId
            __typename
          }
          nextToken
          __typename
        }
        videos {
          items {
            id
            filename
            owner
            enabled
            plates
            watermark
            createdAt
            updatedAt
            albumVideosId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        prices {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        updatedAt
        albumGroupAlbumsId
        __typename
      }
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWebPushAlbumSubscription = /* GraphQL */ `
  mutation UpdateWebPushAlbumSubscription(
    $input: UpdateWebPushAlbumSubscriptionInput!
    $condition: ModelWebPushAlbumSubscriptionConditionInput
  ) {
    updateWebPushAlbumSubscription(input: $input, condition: $condition) {
      id
      albumID
      album {
        id
        name
        owner
        photos {
          items {
            id
            image
            pubimage
            owner
            enabled
            plates
            watermark
            createdAt
            updatedAt
            albumPhotosId
            __typename
          }
          nextToken
          __typename
        }
        videos {
          items {
            id
            filename
            owner
            enabled
            plates
            watermark
            createdAt
            updatedAt
            albumVideosId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        prices {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        updatedAt
        albumGroupAlbumsId
        __typename
      }
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWebPushAlbumSubscription = /* GraphQL */ `
  mutation DeleteWebPushAlbumSubscription(
    $input: DeleteWebPushAlbumSubscriptionInput!
    $condition: ModelWebPushAlbumSubscriptionConditionInput
  ) {
    deleteWebPushAlbumSubscription(input: $input, condition: $condition) {
      id
      albumID
      album {
        id
        name
        owner
        photos {
          items {
            id
            image
            pubimage
            owner
            enabled
            plates
            watermark
            createdAt
            updatedAt
            albumPhotosId
            __typename
          }
          nextToken
          __typename
        }
        videos {
          items {
            id
            filename
            owner
            enabled
            plates
            watermark
            createdAt
            updatedAt
            albumVideosId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        prices {
          unit_amount
          license
          price_name
          max_size
          currency
          __typename
        }
        updatedAt
        albumGroupAlbumsId
        __typename
      }
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      prefix
      price {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      prefix
      price {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      prefix
      price {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const notifyAlbumSubscribers = /* GraphQL */ `
  mutation NotifyAlbumSubscribers($input: notifyAlbumSubscribersInput!) {
    notifyAlbumSubscribers(input: $input) {
      id
      name
      owner
      photos {
        items {
          id
          image
          pubimage
          owner
          enabled
          plates
          watermark
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          createdAt
          updatedAt
          albumPhotosId
          __typename
        }
        nextToken
        __typename
      }
      videos {
        items {
          id
          filename
          owner
          enabled
          plates
          watermark
          prices {
            unit_amount
            license
            price_name
            max_size
            currency
            __typename
          }
          createdAt
          updatedAt
          albumVideosId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      prices {
        unit_amount
        license
        price_name
        max_size
        currency
        __typename
      }
      updatedAt
      albumGroupAlbumsId
      __typename
    }
  }
`;
