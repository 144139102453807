/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Link } from '@mui/material';
import { Instagram } from '@mui/icons-material';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ContactLayout from "components/Layout/Contact";


function CommercialUse() {
  return (
    <>
<ContactLayout>

        <MKBox
          variant="gradient"
          bgColor="info"
          coloredShadow="info"
          borderRadius="lg"
          p={2}
          mx={2}
          mt={-3}
          >
          <MKTypography variant="h3" color="white">
            Commercial Use
          </MKTypography>
        </MKBox>

        <MKBox p={3} className="info info-horizontal">
          <MKBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="icon icon-primary">
            <i className="material-icons">handshake</i>
          </MKBox>
          <MKBox className="description">
            <MKTypography variant="h5" className="info-title">
              Reach out to talk shop
            </MKTypography>
            <MKTypography className="description">
              If you've spotted a shot that you'd like to use commercially - please reach out! I'd be thrilled to discuss terms, and there's a whole lot more pixels waiting for you in the unwatermarked version. It's like going from a dinky old hardtail to a slick new full-sus plough!
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox p={3} className="info info-horizontal">
          <MKBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="icon icon-primary">
            <i className="material-icons">chat_bubble</i>
          </MKBox>
          <Link href="https://www.instagram.com/shanmtb/" className="btn btn-primary btn-round">
            <MKBox className="description">
              <MKTypography className="description">
                Slide into my DMs on Insta to discuss commercial use of my work!

              </MKTypography>
            </MKBox>
            <MKBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="icon icon-primary">
              <Instagram />
            </MKBox>
          </Link>
        </MKBox>
          </ContactLayout>
    </>
  );
}

export default CommercialUse;
