/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link } from "react-router-dom";

// Material Kit 2 React components

import React, { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useShoppingCart } from "use-shopping-cart";


//import { listOrders, getOrder, getOrderWithCsId, getPost, fetchLineItemDownloadLink } from "graphql/queries";


function StripeSuccess() {
    const { clearCart } = useShoppingCart();
    let navigate = useNavigate();

    
    const { id, csId } = useParams();
    useEffect(() => {
        
    }, [ id, csId ]);
    
    
    useEffect(() => {
            clearCart();
            console.log("Cart cleared, redirecting in a second...")
            setTimeout(() => navigate(`/order/${id}/csid/${csId}`), 5000);
            console.log("Timer started...")
    }, [])



  return (
    <MKBox component="section" py={0}>
      <Container >
        <Grid container item>
          <MKTypography variant="h3" mb={3} key="sectionHeader">
            Thanks for making a purchase! Redirecting to your order...
            <Link to={`/order/${id}/csid/${csId}`} > Linky... </Link>
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default StripeSuccess;
