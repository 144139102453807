// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import DownloadLineItemModal from "layouts/sections/attention-catchers/modals/components/DownloadLineItemModal";

export function OrderComponent(order) {
    console.log("Order section entered");
    console.log(order);
    if (order.order) {order = order.order }
    return (
        <>
            <Grid container spacing={3}>
                <Card
                    sx={{
                        background: "transparent",
                        boxShadow: "none",
                        overflow: "visible",
                        borderWidth: "0.2rem",
                        padding: "1rem",
                        width: "100%"
                    }}
                    key={order.id}
                >
                    <Grid item xs={12} >
                        <MKBox>

                            <MKTypography>
                                Order ID: {order.id}
                            </MKTypography>
                            <MKTypography>
                                Status: {order.enabled ? "Complete" : "Incomplete"}
                            </MKTypography>
                            <MKBox style={{ display: 'flex', gap: "0.5rem", flexWrap: "wrap" }} >
                                {(order.lineItems) ?
                                    order.lineItems.map((lineItem) => (
                                        <MKBox key={lineItem.productId}>
                                            <DownloadLineItemModal orderId={order.id} lineItem={lineItem} enabled={order.enabled} />
                                            <MKBox key={lineItem.productId + "info"}>
                                                <MKTypography>{lineItem.name}</MKTypography>
                                                <MKTypography>License: {lineItem.priceData.license}</MKTypography>
                                                <MKTypography>Image size: {lineItem.priceData.max_size ? lineItem.priceData.max_size + "pixels" : "original full size"}</MKTypography>

                                            </MKBox>
                                        </MKBox>
                                    ))
                                    : null
                                }
                            </MKBox>
                        </MKBox>
                    </Grid>

                </Card>

            </Grid>
        </>
    )
}


export default OrderComponent;