/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'
import personalUseSpiels from "./spiels";
import React from "react";

function DownloadImageModal({ postinfo }) {
  //const { postid, prices, addItem } = props;
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  function getRandomSpiel() {
    const randomIndex = Math.floor(Math.random() * personalUseSpiels.length);

    return personalUseSpiels[randomIndex].split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));

    return personalUseSpiels[randomIndex];
  }

  return (
    <>
      <MKButton variant="gradient" color="info" onClick={toggleModal}>
        <CloudDownloadIcon icononly="false" />
        Download this image
      </MKButton>
      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={show} timeout={500}>
          <MKBox
            position="relative"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
            maxWidth="550px"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Personal use only</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography variant="body2" color="secondary" fontWeight="regular">
                  {getRandomSpiel()}
                </MKTypography>
              <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Image ID: {postinfo.id}
                </MKTypography>
              </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>

              <MKBox display="flex" alignItems="center" justifyContent="center">
                <MKButton
                  m="auto"
                  zindex="9"
                  variant="gradient"
                  color="info"
                  href={postinfo.imageurl}
                  target="_blank"
                  
                >Download for personal use</MKButton>
              </MKBox>

            </MKBox>

          </MKBox>
        </Slide>
      </Modal>
    </>
  );
}

export default DownloadImageModal;
