// WrappedMaterialReactTable.js

import React from 'react';
import useDynamicTable from './useDynamicTable';
import { MaterialReactTable } from 'material-react-table';

const WrappedMaterialReactTable = ({ queryName }) => {
  const { columns, tableData, loading, error } = useDynamicTable(queryName);
  //console.log("WrappedMaterentered with " + queryName)
  //console.log("columns")
  console.log(columns)
  console.log(tableData)

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return <MaterialReactTable columns={columns} data={tableData} />;
};

export default WrappedMaterialReactTable;