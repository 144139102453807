import {
	Button,
	Card,
	Flex,
	Heading,
	Text,
	Menu,
	MenuButton,
	useTheme,
	Image,
} from '@aws-amplify/ui-react'
import { useState, useEffect } from 'react'
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'
import { fetchCheckoutURL } from 'graphql/queries'
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { signInAsGuest, checkSignInStatus } from 'assets/utils'
import { Authenticator } from '@aws-amplify/ui-react'
import SignInModal from 'layouts/sections/attention-catchers/modals/components/SignInModal'
import MKButton from 'components/MKButton'
import CircularProgress from '@mui/material/CircularProgress';
import { generateClient } from 'aws-amplify/api'
import { getUrl } from 'aws-amplify/storage'


function ShoppingCartSection() {
	const theme = useTheme()
	const { totalPrice, clearCart, addItem, removeItem, setItemQuantity, cartDetails } = useShoppingCart()
	const [products, setProducts] = useState([
		{
			id: 1,
			image: 'https://github.com/mtliendo.png',
			name: 'Test',
			description: 'A sample',
			price: 300,
			currency: "AUD"
		},
	])
	const [isLoading, updateIsLoading] = useState(false);


	useEffect(() => {
		// fetch the products once they're here
	}, [])
		
	const handleCheckout = async (e) => {
		const APIClient = generateClient();
		updateIsLoading(true);
		e.preventDefault()
		//const authMode = await checkSignInStatus() ? "AMAZON_COGNITO_USER_POOLS" : "API_KEY";
		const authMode = await checkSignInStatus() ? "userPool" : "apiKey";
		console.log("Got authMode back: ", authMode)
		const { data } = await APIClient.graphql({
			query: fetchCheckoutURL,
			authMode: authMode,
			variables: {
				input: JSON.stringify(cartDetails),
			},
		}).catch((e) => {
			console.log('the returned error', e);
			updateIsLoading(false);
		})

		const { sessionId } = JSON.parse(data.fetchCheckoutURL)

		window.location.href = sessionId
	}



	return (
		<CheckoutMenu
			cartDetails={cartDetails}
			handleCheckout={handleCheckout}
			clearCart={clearCart}
			totalPrice={totalPrice}
			theme={theme}
			removeItem={removeItem}
			isLoading={isLoading}
			setItemQuantity={setItemQuantity}
			/>
	)
}


const CheckoutMenu = ({
	cartDetails,
	clearCart,
	handleCheckout,
	totalPrice,
	theme,
	removeItem,
	isLoading,
	setItemQuantity
}) => {

		const [updatedCartDetails, setUpdatedCartDetails] = useState(cartDetails)

		const updateCartDetailsWithImages = async () => {
			const updatedDetails = await Promise.all(Object.entries(cartDetails).map(async ([key, value]) => {
				const imageUrl = await getUrl({path: `public/processed/tn-${value.image}`});
				return { ...value, tnUrl: imageUrl.url.href };
			}));
			setUpdatedCartDetails(updatedDetails);
			console.log(updatedDetails);
		};

		const checkQuantitiesInCart = async () => {
			await Promise.all(Object.entries(cartDetails).map(async ([key, value]) => {
				if (value.quantity > 1 && value.id.split('_')[0] === "post") {
					setItemQuantity(value.id, 1)
				}
			}));
		};


		useEffect(() => {
			updateCartDetailsWithImages();
			checkQuantitiesInCart();
		},[cartDetails])

	return (
		//console.log(cartDetails),
		<>
		<SignInModal />	
			<MKBox style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} className="icon icon-primary">
				{Object.entries(updatedCartDetails).map(([key, value]) => (
					<MKBox p={3} className="info info-horizontal" style={{ display: 'flex', justifyContent: 'space-between'}}>
						<MKBox className="description">
							{console.log(value)}
							<MKBox display="flex" justifyContent="space-between">
								<MKBox>
							<MKTypography variant="h5" className="info-title">
								{value.name}
							</MKTypography>
								</MKBox>
								<MKBox>
							<MKTypography className="description">
								{value.formattedValue}
							</MKTypography>
								</MKBox>
							</MKBox>
							<MKBox> <MKButton onClick={() => removeItem(value.id)}> Remove Item</MKButton> </MKBox>
							<MKBox component="img" src={value.tnUrl} maxWidth="100%" />
						{(value.id.split('_')[0] === "post") && 
							<>
							{/* //console.log("Product is a post")
							// do some post specific things here if needed */}
							{(value.license) ? <MKTypography className="description"> License type: {value.license} use </MKTypography> : null}
							<MKTypography className="description"> Image size: 
							{(value.max_size) ? " Long edge " + (value.max_size) + " pixels" : " Full original image size" }
							</MKTypography>
							</>
							}
						</MKBox>
						</MKBox>
				))}
			</MKBox>

			{ isLoading ? 
              <MKBox position="absolute" zIndex="3" top="50%" left="50%" >
              <CircularProgress />
              </MKBox> : null
              }

			<Flex justifyContent={'space-between'} margin={theme.tokens.space.small}>
				<MKButton	onClick={() => clearCart()} color="error" variant="gradient" >
					Clear Cart
				</MKButton>
				<MKButton onClick={handleCheckout} color="success" variant="gradient" >
					Checkout (
					{formatCurrencyString({
						value: totalPrice,
						currency: 'AUD',
					})}
					)
				</MKButton>
			</Flex>
		</>
	)
}

const ProductList = ({ products, addItem }) => {
	return (
		<>
			{products.map((product) => (
				<Card key={product.id} variation="elevated">
					<Image src={product.image} height="250px" />
					{/* Handle signing of the image to pull it here */}
					{/* logic for product type here too
					if product id starts "post" - do some stuff */}

					<Flex direction={'column'}>
						<Heading level={5}>{product.name}</Heading>
						<Text>{product.description}</Text>
						<Flex justifyContent={'space-between'} alignItems="center">
							<Text>
								{formatCurrencyString({
									value: product.price,
									currency: 'AUD',
								})}
							</Text>
							<Button variation="primary" onClick={() => addItem(product)}>
								Add to Cart
							</Button>
						</Flex>
					</Flex>
				</Card>
			))}
		</>
	)
}

export default ShoppingCartSection;
